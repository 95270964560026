/*------------------------------------------------------------------ 
[Master Stylesheet] Project: Admin Dashboard Version: 1.2.1
 -------------------------------------------------------------------*/

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro|Roboto|Rubik");

/* Set light and dark mode preferences here. */
body {
  font-family: "Rubik", "Source Sans Pro", "Roboto", sans-serif !important;
  --primary-color: #fff; /* Used as the default background color for UI elements*/
  --secondary-color: #88c0d0;
  --primary-text-color: #222; /* For all header elements and default text color*/
  --secondary-text-color: #c6ccd8; /*For sub headers and alternate text*/
  --primary-green: #3ac17e; /*Used for success alerts and `good` status*/
  --primary-blue: #0caee3; /*Used for info alerts, button highlight/hover color*/
  --secondary-blue: #03278e; /*Default dark blue color. Used for sidebar background and other accents.*/
  --primary-red: #fd2b35;
  --primary-orange: #f3912b;
  --card-bg: var(--primary-color); /*Widget card background color*/
  --card-shadow: rgba(149, 157, 165, 0.2);
  --background: #f1f2f7; /*Default app background*/
  --site-header: var(--background);
  --btn-bg: var(--primary-color);
  --btn-text-color: var(--primary-text-color);
  --btn-hover-bg: var(--primary-blue);
  --btn-hover-text-color: #fff;
  --sidebar-bg: #192734;
  --hover: #a2d8f1;
  --border: #e1e1ee;
  --sidebar-item: rgba(149, 157, 165, 0.2);
  --sidebar-hover: #22303c;
}

.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed var(--border);
  background-color: var(--background);
  color: #bdbdbd;
  margin-bottom: 20px;
}

.accept {
  border-color: var(--primary-green) !important;
  background-color: rgba(58, 193, 126, 0.25);
}

.reject {
  border-color: var(--primary-red) !important;
  background-color: rgba(253, 43, 54, 0.25);
}

body.dark-theme {
  --primary-color: #192734; /* Used as the default background color for UI elements*/
  --secondary-color: #88c0d0;
  --primary-text-color: #fff; /* For all header elements and default text color*/
  --secondary-text-color: #8899a6; /*For sub headers and alternate text*/
  --primary-green: #3ac17e; /*Used for success alerts and `good` status*/
  --primary-blue: #0caee3; /*Used for info alerts, button highlight/hover color*/
  --secondary-blue: #03278e; /*Default dark blue color. Used for sidebar background and other accents.*/
  --primary-red: #fd2b35;
  --primary-orange: #f3912b;
  --card-bg: var(--primary-color); /*Widget card background color*/
  --card-shadow: #22272e;
  --background: #15202b; /*Default app background*/
  --site-header: var(--background);
  --btn-bg: var(--primary-color);
  --btn-text-color: var(--primary-text-color);
  --btn-hover-bg: var(--hover);
  --btn-hover-text-color: #fff;
  --sidebar-bg: var(--card-bg);
  --hover: #22303c;
  --border: #8899a6;
}

/* Reset Styles */
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}
.form-control,
.tt-query:focus,
.tt-hint:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: 0;
  background: var(--card-bg);
  color: var(--primary-text-color);
}
a,
h1,
h2,
h3,
h4,
h5,
p {
  text-shadow: none;
  color: var(--primary-text-color);
}
[type="button"]:focus,
a:active,
a:focus,
a:visited,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner,
select::-moz-focus-inner {
  outline: 0;
}
input,
.form-control:focus,
.typeahead:focus,
.tt-query:focus,
.tt-hint:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
  outline-width: 0;
  outline-color: transparent;
  box-shadow: none;
  outline-style: none;
  background: var(--card-bg);
  color: var(--primary-text-color);
}
.form-control:disabled {
  background: var(--background);
}
textarea {
  resize: none;
  overflow-x: hidden;
}

a,
a:active,
a:checked,
a:focus,
a:hover,
a:visited,
body,
input,
input:active,
input:focus,
input:hover,
input:visited,
select,
select:active,
select:focus,
select:visited,
textarea,
textarea:active,
textarea:focus,
textarea:hover,
textarea:visited {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: var(--primary-text-color);
}

.btn.active.focus,
button,
button:active,
button:checked,
button:focus,
button:hover,
button:visited {
  outline: 0;
  outline-offset: 0;
}
.bootstrap-select .dropdown-toggle:focus {
  outline: 0;
  outline-offset: 0;
}
.dropdown-menu > li > a:active,
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:visited {
  outline: 0;
}
a:focus,
input:focus {
  border-color: transparent;
  outline: none;
}

.dropdown-item:hover {
  background-color: var(--hover);
}

@font-face {
  font-family: HelveticaNeue;
  src: url("../fonts/HelveticaNeue/helveticaneue.ttf");
  font-weight: normal;
}
@font-face {
  font-family: HelveticaNeue;
  src: url("../fonts/HelveticaNeue/HelveticaNeue-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: AvenirNext;
  src: url("../fonts/AvenirNext/AvenirNext-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: AvenirNext;
  src: url("../fonts/AvenirNext/AvenirNext-DemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: AndaleMono;
  src: url("../fonts/Andale-Mono.ttf");
  font-weight: 400;
}
/* Typography */
body {
  font-size: 1rem;
  font-family: "Rubik", "Roboto", sans-serif;
  font-weight: initial;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
  color: var(--primary-text-color);
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 500;
  line-height: 1.3;
  color: var(--primary-text-color);
}

p {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  line-height: 1.4rem;
}
h1,
.h1 {
  font-size: 2.125rem;
}
h2,
.h2 {
  font-size: 1.875rem;
}
h3,
.h3 {
  font-size: 1.5rem;
}
h4,
.h4 {
  font-size: 1.25rem;
}
h5,
.h5 {
  font-size: 1rem;
}
h6,
.h6 {
  font-size: 0.9375rem;
  color: var(--primary-text-color);
}
.display-1 {
  font-size: 3.75rem;
}
@media (max-width: 991px) {
  .display-1 {
    font-size: 3rem;
  }
}
.display-2 {
  font-size: 3.125rem;
}
@media (max-width: 991px) {
  .display-2 {
    font-size: 2.5rem;
  }
}
.display-3 {
  font-size: 2.5rem;
}
@media (max-width: 991px) {
  .display-3 {
    font-size: 2rem;
  }
}
.display-4 {
  font-size: 1.875rem;
}
@media (max-width: 991px) {
  .display-4 {
    font-size: 1.5rem;
  }
}
.display-5 {
  font-size: 1.25rem;
}
@media (max-width: 991px) {
  .display-5 {
    font-size: 1rem;
  }
}
.font-weight-medium {
  font-weight: 500;
}
/* Miscellanoeous */
body,
html {
  overflow-x: hidden;
  padding-right: 0 !important;
}
*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto;
}
.container-scroller {
  overflow: hidden;
}
/* Footer */
.footer {
  background: #eff0f6;
  padding: 30px 1rem;
  transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  font-size: calc(0.875rem - 0.05rem);
  font-family: "Rubik", "Roboto", sans-serif;
  font-weight: 400;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
/* Utilities */
.grid-margin {
  margin-bottom: 1.875rem;
  position: relative;
}
@media (min-width: 576px) {
  .grid-margin-sm-0 {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .grid-margin-md-0 {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .grid-margin-lg-0 {
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .grid-margin-xl-0 {
    margin-bottom: 0;
  }
}
.img-lg {
  width: 92px;
  height: 92px;
}
.img-sm {
  width: 43px;
  height: 43px;
}
.img-xs {
  width: 37px;
  height: 37px;
}
.img-ss,
.settings-panel .color-tiles .tiles {
  width: 26px;
  height: 26px;
}
.stretch-card > .card {
  width: 100%;
  min-width: 100%;
}
.stretch-card > .card + .card {
  margin-top: 20px;
}
.height-100 .card {
  height: 100%;
}
.height-50 .card {
  height: calc((100% / 2) - 10px);
}
@media (min-width: 576px) {
  .border-right-sm {
    border-right: 1px solid #eeeff7;
  }
}
@media (min-width: 768px) {
  .border-right-md {
    border-right: 1px solid #eeeff7;
  }
}
@media (min-width: 992px) {
  .border-right-lg {
    border-right: 1px solid #eeeff7;
  }
}
@media (min-width: 1200px) {
  .border-right-xl {
    border-right: 1px solid #eeeff7;
  }
}
@media (min-width: 576px) {
  .border-left-sm {
    border-left: 1px solid #eeeff7;
  }
}
@media (min-width: 768px) {
  .border-left-md {
    border-left: 1px solid #eeeff7;
  }
}
@media (min-width: 992px) {
  .border-left-lg {
    border-left: 1px solid #eeeff7;
  }
}
.text-gray,
.card .card-subtitle {
  color: var(--secondary-text-color);
}
.text-black {
  color: #000;
}
.text-small {
  font-size: 12px;
}
.flex-grow {
  flex-grow: 1;
}
.hover-box-shadow-0:hover {
  box-shadow: none;
}
.demo-modal {
  position: static;
  display: block;
}
.demo-modal .modal-dialog.modal-lg {
  max-width: 100%;
}
.dropdown-menu-static-demo {
  height: 250px;
  margin-bottom: 50px;
}

.dropdown-header {
  color: var(--primary-text-color);
}

.dropdown-menu-static-demo .dropdown-menu {
  z-index: 0;
}
.chartjs-legend .goals-chart .item .legend-label {
  width: 17px;
  height: 17px;
  border-radius: 17px;
  margin-bottom: 10px;
}
.chartjs-legend .goals-chart-label {
  font-size: 14px;
  padding-left: 5px;
}
.demo-vector-map {
  width: 100%;
  height: 500px;
}

/* change background and tip color to green */
.custom-mapbox-popup .mapboxgl-popup-content {
  background-color: var(--card-bg);
  padding: 20px 10px 15px;
}
.custom-mapbox-popup .mapboxgl-popup-tip {
  border-top-color: var(--card-bg);
}

.custom-mapbox-popup .mapboxgl-popup-close-button {
  color: var(--primary-text-color);
}

.table.table-hover tbody tr:hover td .icon-rounded-primary {
  background: #16a6dc;
  -webkit-transition: background 0.2s linear;
  -moz-transition: background 0.2s linear;
  -ms-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
  transition: background 0.2s linear;
}
.table.table-hover tbody tr:hover td .icon-rounded-primary h4 {
  color: var(--primary-color);
  background: var(--primary-color);
}
div > table > tbody > tr > td {
  color: var(--primary-text-color);
  background: var(--primary-color);
}

g > text > tspan {
  fill: var(--primary-text-color);
}

#gauge2 text tspan {
  fill: var(--primary-text-color);
  transition: color 0.6s ease-out;
}

#gauge2 path:nth-child(3) {
  fill: var(--background);
}

#gauge1 path:nth-child(3) {
  fill: var(--background);
}

#gauge1 text tspan {
  fill: var(--primary-text-color);
  transition: color 0.6s ease-out;
}

g > text > tspan {
  fill: var(--primary-text-color);
}
/*-------------------------------------------------------------------*/
/* === Components === */
/* Accordions */
.accordion .card {
  margin-bottom: 0.75rem;
  box-shadow: 0 2px 1px 0 rgba(49, 57, 67, 0.07);
  border-radius: 0.25rem;
}
.accordion .card .card-header {
  background-color: var(--card-bg);
  border: none;
  padding: 2rem;
}
.accordion .card .card-header * {
  font-weight: 400;
  font-size: 1rem;
}
.accordion .card .card-header a {
  display: block;
  color: inherit;
  text-decoration: none;
  font-size: inherit;
  position: relative;
  -webkit-transition: color 0.5s ease;
  -moz-transition: color 0.5s ease;
  -ms-transition: color 0.5s ease;
  -o-transition: color 0.5s ease;
  transition: color 0.5s ease;
  padding-right: 1.5rem;
  line-height: 1.5rem;
}
.accordion .card .card-header a:before {
  font-family: "Material Design Icons";
  position: absolute;
  right: 7px;
  top: 0;
  font-size: 18px;
  display: block;
}
.accordion .card .card-header a[aria-expanded="true"] {
  color: #16a6dc;
}
.accordion .card .card-header a[aria-expanded="true"]:before {
  content: "\F374";
}
.accordion .card .card-header a[aria-expanded="false"]:before {
  content: "\F415";
}
.accordion .card .card-body {
  font-size: 14px;
  padding: 0 2rem 2rem 2rem;
  font-weight: 400;
  line-height: 1.5;
}
.accordion .card .card-body i {
  font-size: 1.25rem;
}
.accordion.accordion-solid-header .card {
  padding: 0;
  border-radius: 6px;
}
.accordion.accordion-solid-header .card .card {
  background: #f7f8fb;
}
.accordion.accordion-solid-header .card .card-header {
  padding: 0;
}
.accordion.accordion-solid-header .card .card-header a {
  padding: 1rem 3.5rem 1rem 2rem;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  font-weight: 500;
}
.accordion-solid-header {
  border-radius: 6px;
}
.accordion.accordion-solid-header .card .card-header a > span {
  color: #6046ff;
}
.accordion.accordion-solid-header .card .card-header a > em {
  font-style: normal;
  float: right;
  font-weight: 400;
  font-size: 0.75rem;
}
.accordion.accordion-solid-header .card .card-header a:before {
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 2rem;
}
.accordion.accordion-solid-header .card .card-header a[aria-expanded="true"] {
  color: var(--primary-text-color);
  border-radius: 6px 6px 0 0;
  border-bottom: 1px solid #eeeff7;
}
.accordion.accordion-solid-header
  .card
  .card-header
  a[aria-expanded="true"]:before {
  content: "\F35D";
  color: #009260;
}
.accordion.accordion-solid-header
  .card
  .card-header
  a[aria-expanded="null"]:before {
  color: #009260;
}
.accordion.accordion-solid-header
  .card
  .card-header
  a[aria-expanded="false"]:before {
  content: "\F360";
  color: #009260;
}
.accordion.accordion-solid-header .card .card-body {
  padding: 2rem;
}
.accordion.accordion-solid-content .card {
  padding: 0;
}
.accordion.accordion-solid-content .card .card-header {
  padding: 0;
}
.accordion.accordion-solid-content .card .card-header a {
  padding: 2rem 4.5rem 2rem 2rem;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.accordion.accordion-solid-content .card .card-header a:before {
  top: 40%;
  right: 40px;
}
.accordion.accordion-solid-content .card .card-header a[aria-expanded="true"] {
  color: inherit;
}
.accordion.accordion-solid-content
  .card
  .card-header
  a[aria-expanded="true"]:before {
  content: "\F143";
}
.accordion.accordion-solid-content
  .card
  .card-header
  a[aria-expanded="false"]:before {
  content: "\F140";
}
.accordion.accordion-solid-content .card .card-body {
  padding: 2rem;
  background: #19c0ff;
  color: var(--primary-color);
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.alignleft {
  float: left;
  font-weight: 700;
}
.alignright {
  float: right;
}
/* Badges */
.badge {
  border-radius: 0;
  font-size: 12px;
  line-height: 1;
  padding: 0.375rem 0.7rem;
  font-weight: normal;
}
.badge.badge-pill {
  border-radius: 6px;
}
/*Badge outlined variations*/
.badge-outline-primary {
  color: #5b626a !important;
  border: 1px solid #5b626a;
  border-radius: 100% !important;
  padding: 0.375rem 0.617rem;
}
/* Buttons */
.btn,
.ajax-upload-dragdrop .ajax-file-upload,
.swal2-modal .swal2-buttonswrapper .swal2-styled,
.swal2-modal .swal2-buttonswrapper .swal2-styled.swal2-confirm,
.swal2-modal .swal2-buttonswrapper .swal2-styled.swal2-cancel {
  font-size: 0.775rem;
  line-height: 1;
  font-weight: 400;
  min-width: 80px;
}
.btn i,
.ajax-upload-dragdrop .ajax-file-upload i,
.swal2-modal .swal2-buttonswrapper .swal2-styled i,
.swal2-modal .swal2-buttonswrapper .swal2-styled.swal2-confirm i,
.swal2-modal .swal2-buttonswrapper .swal2-styled.swal2-cancel i {
  font-size: 1rem;
}
.btn.btn-rounded,
.ajax-upload-dragdrop .btn-rounded.ajax-file-upload,
.swal2-modal .swal2-buttonswrapper .btn-rounded.swal2-styled {
  border-radius: 6px;
}
.btn.btn-sm,
.btn-group-sm > .btn,
.fc .btn-group-sm > button,
.ajax-upload-dragdrop .btn-group-sm > .ajax-file-upload,
.swal2-modal .swal2-buttonswrapper .btn-group-sm > .swal2-styled,
.ajax-upload-dragdrop .btn-sm.ajax-file-upload,
.ajax-upload-dragdrop .fc button.ajax-file-upload,
.fc .ajax-upload-dragdrop button.ajax-file-upload,
.swal2-modal .swal2-buttonswrapper .btn-sm.swal2-styled,
.swal2-modal .swal2-buttonswrapper .fc button.swal2-styled,
.fc .swal2-modal .swal2-buttonswrapper button.swal2-styled {
  font-size: 0.875rem;
}
.btn.btn-lg,
.btn-group-lg > .btn,
.fc .btn-group-lg > button,
.ajax-upload-dragdrop .btn-group-lg > .ajax-file-upload,
.swal2-modal .swal2-buttonswrapper .btn-group-lg > .swal2-styled,
.ajax-upload-dragdrop .btn-lg.ajax-file-upload,
.swal2-modal .swal2-buttonswrapper .btn-lg.swal2-styled {
  font-size: 0.875rem;
}
.btn.btn-icon,
.ajax-upload-dragdrop .btn-icon.ajax-file-upload,
.swal2-modal .swal2-buttonswrapper .btn-icon.swal2-styled {
  width: 42px;
  height: 42px;
  padding: 0;
}
.btn.btn-icon-text .btn-icon-prepend,
.ajax-upload-dragdrop .btn-icon-text.ajax-file-upload .btn-icon-prepend,
.swal2-modal
  .swal2-buttonswrapper
  .btn-icon-text.swal2-styled
  .btn-icon-prepend {
  margin-right: 0.5rem;
}
.btn.btn-icon-text .btn-icon-append,
.ajax-upload-dragdrop .btn-icon-text.ajax-file-upload .btn-icon-append,
.swal2-modal
  .swal2-buttonswrapper
  .btn-icon-text.swal2-styled
  .btn-icon-append {
  margin-left: 0.5rem;
}
.btn.btn-social-icon,
.ajax-upload-dragdrop .btn-social-icon.ajax-file-upload,
.swal2-modal .swal2-buttonswrapper .btn-social-icon.swal2-styled {
  width: 50px;
  height: 50px;
  padding: 0;
}

/* inverse buttons */
.btn-primary,
.btn-inverse-primary {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background-color: var(--primary-blue);
  color: #fff;
  border-color: var(--primary-blue);
}
.btn-primary:hover,
.btn-inverse-primary:hover {
  background-color: #137EB0;
  opacity: 1.0;
  cursor: pointer;
  border-color: transparent;
}

.btn-primary-close {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background-color: var(--btn-bg);
  color: var(--btn-text-color);
  border-color: var(--primary-blue);
}
.btn-primary-close:hover,
.btn-inverse-close:hover {
  -webkit-box-shadow: 0 13px 21px -4px var(--card-shadow);
  -moz-box-shadow: 0 13px 21px -4px var(--card-shadow);
  box-shadow: 0 13px 21px -4px var(--card-shadow);
  background-color: var(--primary-red);
  border-color: transparent;
  color: #fff;
}

.btn-inverse-secondary {
  background-color: rgba(144, 151, 196, 0.2);
  background-image: none;
  border-color: rgba(144, 151, 196, 0);
}
.btn-inverse-secondary:not(.btn-inverse-light) {
  color: #9097c4;
  border-radius: 6px;
}
.btn-inverse-secondary:hover {
  color: var(--primary-color);
  background-color: #9097c4;
  border-color: #9097c4;
}
.btn-inverse-secondary.focus,
.btn-inverse-secondary:focus {
  box-shadow: 0 0 0 3px rgba(144, 151, 196, 0.5);
}
.btn-inverse-secondary.disabled,
.btn-inverse-secondary:disabled {
  color: #9097c4;
  background-color: transparent;
}
.btn-inverse-secondary.active,
.btn-inverse-secondary:active,
.show > .btn-inverse-secondary.dropdown-toggle {
  color: var(--primary-color);
  background-color: #9097c4;
  border-color: #9097c4;
}
.collapse .show {
  background-color: var(--card-bg);
}
.btn-secondary,
.wizard > .actions .disabled a,
.btn-inverse-secondary,
.btn-outline-secondary,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .editable-cancel {
  -webkit-border-radius: 0;
  border-radius: 0;
}
.btn-outline-primary {
  background: var(--btn-bg);
}
.bg-table {
  color: var(--background) !important;
}
/* .btn-secondary:hover,
.wizard > .actions .disabled a:hover,
.btn-inverse-secondary:hover,
.btn-outline-secondary:hover,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .editable-cancel:hover {
  -webkit-box-shadow: 0 13px 21px -4px var(--btn-hover-bg);
  -moz-box-shadow: 0 13px 21px -4px var(--btn-hover-bg);
  box-shadow: 0 13px 21px -4px var(--btn-hover-bg); */

.btn-success,
.btn-inverse-success,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .editable-submit {
  border-radius: 0;
  color: #fff;
}
.btn-success:hover,
.btn-outline-success:hover,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .editable-submit:hover {
    background-color: var(--primary-green);

}
.btn-info:hover,
.btn-outline-info:hover {
  -webkit-box-shadow: 0 13px 21px -4px #bcbbcc;
  -moz-box-shadow: 0 13px 21px -4px #bcbbcc;
  box-shadow: 0 13px 21px -4px #bcbbcc;
}
.btn-outline-light {
  border-color: #f3f3f3;
}
.btn-outline-success {
  color: var(--primary-green);
}
/* Cards */
.card {
  box-shadow: var(--card-shadow) 0px 8px 24px;
  -webkit-box-shadow: var(--card-shadow) 0px 8px 24px;
  -moz-box-shadow: var(--card-shadow)0px 8px 24px;
  -ms-box-shadow: var(--card-shadow) 0px 8px 24px;
  border: none;
  background-color: var(--card-bg);
  border-radius: 3px;
}

.card-feedback {
  box-shadow: 5px 7px 26px -5px var(--card-shadow);
  -webkit-box-shadow: 5px 7px 26px -5px var(--card-shadow);
  -moz-box-shadow: 5px 7px 26px -5px var(--card-shadow);
  -ms-box-shadow: 5px 7px 26px -5px var(--card-shadow);
  border: none;
  background-color: var(--card-bg);
}

.card .card-body {
  padding: 1.5rem 1.28rem;
}

.card .card-body + .card-body {
  padding-top: 1rem;
}
.card .card-title {
  color: var(--primary-text-color);
  margin-bottom: 1.5rem;
  text-transform: capitalize;
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: normal;
}
.card .card-title strong {
  color: var(--primary-text-color);
  font-weight: bold;
}
.card .card-subtitle {
  font-weight: 400;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.card .card-description {
  margin-bottom: 2.25rem;
  font-weight: 400;
  color: #76838f;
  line-height: 1.2rem;
}
.card.card-outline-success {
  border: 1px solid #19c0ff;
}
.card.card-outline-primary {
  border: 1px solid #16a6dc;
}
.card.card-outline-warning {
  border: 1px solid #f5a623;
}
.card.card-outline-danger {
  border: 1px solid #e71414;
}
.card.card-rounded {
  border-radius: 6px;
}
.card.card-faded {
  background: #b5b0b2;
  border-color: #b5b0b2;
}
.card.card-circle-progress {
  color: var(--primary-color);
  text-align: center;
}
.card.card-img-holder {
  position: relative;
}
.card.card-img-holder .card-img-absolute {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}
.card-body .d-flex h1 {
  font-size: 32px;
  white-space: nowrap;
  color: var(--primary-text-color);
}
.card-body .d-flex .nav-link {
  padding-bottom: 0;
  font-size: 24px;
}
.card-body .d-flex .nav-link .mdi {
  color: var(--secondary-text-color);
}
.card.card-weather .weather-daily .weather-day {
  opacity: 0.5;
  font-weight: 900;
}
.card.card-weather .weather-daily i {
  font-size: 20px;
}
.card.card-weather .weather-daily .weather-temp {
  margin-top: 0.5rem;
  margin-bottom: 0;
  opacity: 0.5;
  font-size: 0.75rem;
}
.card-inverse-primary {
  background: rgba(86, 70, 255, 0.2);
  border: 1px solid #4f40eb;
  color: #4135c2;
}
.card-inverse-secondary {
  background: rgba(144, 151, 196, 0.2);
  border: 1px solid #848bb4;
  color: #6d7395;
}
.card-inverse-success {
  background: rgba(0, 192, 126, 0.2);
  border: 1px solid #00b174;
  color: #009260;
}
.card-inverse-info {
  background: rgba(77, 124, 255, 0.2);
  border: 1px solid #4772eb;
  color: #3b5ec2;
}
.card-inverse-warning {
  background: rgba(245, 166, 35, 0.2);
  border: 1px solid #e19920;
  color: #ba7e1b;
}
.card-inverse-danger {
  background: rgba(252, 86, 97, 0.2);
  border: 1px solid #e84f59;
  color: #c0414a;
}
.card-inverse-light {
  background: rgba(248, 249, 250, 0.2);
  border: 1px solid #e4e5e6;
  color: #bcbdbe;
}
.card-inverse-dark {
  background: rgba(61, 68, 101, 0.2);
  border: 1px solid #383f5d;
  color: #2e344d;
}
/* Checkboxes and Radios */
.form-check {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 0;
}
.form-check .form-check-label {
  min-height: 18px;
  display: block;
  margin-left: 1.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.form-check .form-check-label input {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0;
  margin-top: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}
.form-check .form-check-label input[type="checkbox"] + .input-helper:before,
.form-check .form-check-label input[type="checkbox"] + .input-helper:after {
  position: absolute;
  top: 0;
  left: 0;
}
.form-check .form-check-label input[type="checkbox"] + .input-helper:before {
  content: "";
  width: 18px;
  height: 18px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  border: solid #16a6dc;
  border-width: 2px;
  -webkit-transition: all;
  -moz-transition: all;
  -ms-transition: all;
  -o-transition: all;
  transition: all;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}
.form-check .form-check-label input[type="checkbox"] + .input-helper:after {
  -webkit-transition: all;
  -moz-transition: all;
  -ms-transition: all;
  -o-transition: all;
  transition: all;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  font-family: "Material Design Icons";
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  content: "\F12C";
  font-size: 0.9375rem;
  font-weight: bold;
  color: var(--primary-color);
}
.form-check
  .form-check-label
  input[type="checkbox"]:checked
  + .input-helper:before {
  background: #16a6dc;
  border-width: 0;
}
.form-check
  .form-check-label
  input[type="checkbox"]:checked
  + .input-helper:after {
  width: 18px;
  opacity: 1;
  line-height: 18px;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.form-check
  .form-check-label
  input[type="checkbox"]:disabled
  + .input-helper:before {
  border-color: #eeeff7;
}
.form-check
  .form-check-label
  input[type="checkbox"]:disabled:checked
  + .input-helper:after {
  background: #eeeff7;
  color: var(--primary-color);
}
.form-check .form-check-label input[type="radio"] + .input-helper:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  border: solid #16a6dc;
  border-width: 2px;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all;
  -moz-transition: all;
  -ms-transition: all;
  -o-transition: all;
  transition: all;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}
.form-check .form-check-label input[type="radio"] + .input-helper:after {
  content: "";
  width: 8px;
  height: 8px;
  background: var(--primary-color);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  top: 6px;
  left: 6px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  position: absolute;
}
.form-check
  .form-check-label
  input[type="radio"]:checked
  + .input-helper:before {
  background: #16a6dc;
  border-width: 0;
}
.form-check
  .form-check-label
  input[type="radio"]:checked
  + .input-helper:after {
  opacity: 1;
  line-height: 1.5;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.form-check
  .form-check-label
  input[type="radio"]:disabled
  + .input-helper:before {
  border-color: #eeeff7;
}
.form-check
  .form-check-label
  input[type="radio"]:disabled:checked
  + .input-helper:before {
  background: #eeeff7;
}
.form-check
  .form-check-label
  input[type="radio"]:disabled:checked
  + .input-helper:after {
  background: var(--primary-color);
}
.form-check-primary.form-check
  label
  input[type="checkbox"]
  + .input-helper:before,
.form-check-primary.form-check
  label
  input[type="radio"]
  + .input-helper:before {
  border-color: #16a6dc;
}
.form-check-primary.form-check
  label
  input[type="checkbox"]:checked
  + .input-helper:before,
.form-check-primary.form-check
  label
  input[type="radio"]:checked
  + .input-helper:before {
  background: #16a6dc;
}
.form-check-secondary.form-check
  label
  input[type="checkbox"]
  + .input-helper:before,
.form-check-secondary.form-check
  label
  input[type="radio"]
  + .input-helper:before {
  border-color: #9097c4;
}
.form-check-secondary.form-check
  label
  input[type="checkbox"]:checked
  + .input-helper:before,
.form-check-secondary.form-check
  label
  input[type="radio"]:checked
  + .input-helper:before {
  background: #9097c4;
}
.form-check-success.form-check
  label
  input[type="checkbox"]
  + .input-helper:before,
.form-check-success.form-check
  label
  input[type="radio"]
  + .input-helper:before {
  border-color: #19c0ff;
}
.form-check-success.form-check
  label
  input[type="checkbox"]:checked
  + .input-helper:before,
.form-check-success.form-check
  label
  input[type="radio"]:checked
  + .input-helper:before {
  background: #19c0ff;
}
.form-check-info.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-info.form-check label input[type="radio"] + .input-helper:before {
  border-color: #4d7cff;
}
.form-check-info.form-check
  label
  input[type="checkbox"]:checked
  + .input-helper:before,
.form-check-info.form-check
  label
  input[type="radio"]:checked
  + .input-helper:before {
  background: #4d7cff;
}
.form-check-warning.form-check
  label
  input[type="checkbox"]
  + .input-helper:before,
.form-check-warning.form-check
  label
  input[type="radio"]
  + .input-helper:before {
  border-color: #f5a623;
}
.form-check-warning.form-check
  label
  input[type="checkbox"]:checked
  + .input-helper:before,
.form-check-warning.form-check
  label
  input[type="radio"]:checked
  + .input-helper:before {
  background: #f5a623;
}
.form-check-danger.form-check
  label
  input[type="checkbox"]
  + .input-helper:before,
.form-check-danger.form-check label input[type="radio"] + .input-helper:before {
  border-color: #ff469d;
}
.form-check-danger.form-check
  label
  input[type="checkbox"]:checked
  + .input-helper:before,
.form-check-danger.form-check
  label
  input[type="radio"]:checked
  + .input-helper:before {
  background: #ff469d;
}
.form-check-light.form-check
  label
  input[type="checkbox"]
  + .input-helper:before,
.form-check-light.form-check label input[type="radio"] + .input-helper:before {
  border-color: #f8f9fa;
}
.form-check-light.form-check
  label
  input[type="checkbox"]:checked
  + .input-helper:before,
.form-check-light.form-check
  label
  input[type="radio"]:checked
  + .input-helper:before {
  background: #f8f9fa;
}
.form-check-dark.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-dark.form-check label input[type="radio"] + .input-helper:before {
  border-color: #3d4465;
}
.form-check-dark.form-check
  label
  input[type="checkbox"]:checked
  + .input-helper:before,
.form-check-dark.form-check
  label
  input[type="radio"]:checked
  + .input-helper:before {
  background: #3d4465;
}
/* Dropdowns */
.dropdown .dropdown-toggle:after {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 0;
  font: normal normal normal 24px/1 "Material Design Icons";
  content: "\F140";
  width: auto;
  height: auto;
  vertical-align: middle;
  line-height: 0.625rem;
  font-size: 1.22rem;
  margin-left: 0;
}
.dropdown .dropdown-menu {
  background: var(--card-bg);
  font-size: 0.875rem;
  -webkit-box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
  box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
  -webkit-border-radius: 0;
  border-radius: 0;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.dropdown .dropdown-menu .dropdown-item {
  font-size: 0.875rem;
  padding: 0.65rem 1.5rem;
  color: var(--primary-text-color);
}
.dropdown .dropdown-menu .dropdown-item:active {
  background: initial;
}

/* Forms */
.select-pageSize {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #ebebeb;
  background-color: var(--primary-color);
  width: 64px;
  border-radius: 6px;
  height: 39px;
}
.form-group {
  margin-bottom: 1.5rem;
}

.input-group-append,
.input-group-prepend {
  color: #c9c8c8;
  width: auto;
  border: none;
}
.input-group-append .input-group-text,
.input-group-prepend .input-group-text {
  border-color: #eeeff7;
  padding: 0.875rem 0.75rem;
  color: #c9c8c8;
}
.form-control,
.asColorPicker-input,
.dataTables_wrapper select,
.tt-query,
.tt-hint {
  border: 1px solid #eeeff7;
  font-weight: 400;
  font-size: 0.875rem;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  color: var(--primary-text-color);
}

select.form-control,
select.asColorPicker-input,
.dataTables_wrapper select,
select.tt-hint {
  padding: 0.4375rem 0.75rem;
  border: 0;
  outline: 1px solid #eeeff7;
  color: #c9c8c8;
}
select.form-control:focus,
select.asColorPicker-input:focus,
.dataTables_wrapper select:focus,
select.tt-query:focus,
select.tt-hint:focus {
  outline: 1px solid #eeeff7;
}
select.form-control.border-primary,
select.border-primary.asColorPicker-input,
.dataTables_wrapper select.border-primary,
select.border-primary.tt-query,
select.border-primary.tt-hint {
  outline: 1px solid #16a6dc;
}
select.form-control.border-primary:focus,
select.border-primary.asColorPicker-input:focus,
.dataTables_wrapper select.border-primary:focus,
select.border-primary.tt-query:focus,
select.border-primary.tt-hint:focus {
  outline: 1px solid #16a6dc;
}
select.form-control.border-secondary,
select.border-secondary.asColorPicker-input,
.dataTables_wrapper select.border-secondary,
select.border-secondary.tt-query,
select.border-secondary.tt-hint {
  outline: 1px solid #9097c4;
}
select.form-control.border-secondary:focus,
select.border-secondary.asColorPicker-input:focus,
.dataTables_wrapper select.border-secondary:focus,
select.border-secondary.tt-query:focus,
select.border-secondary.tt-hint:focus {
  outline: 1px solid #9097c4;
}
select.form-control.border-success,
select.border-success.asColorPicker-input,
.swal2-modal
  .swal2-buttonswrapper
  select.asColorPicker-input.swal2-styled.swal2-confirm,
.dataTables_wrapper select.border-success,
.dataTables_wrapper
  .swal2-modal
  .swal2-buttonswrapper
  select.swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .dataTables_wrapper
  select.swal2-styled.swal2-confirm,
swal2-modal .swal2-buttonswrapper select.swal2-styled.swal2-confirm.tt-query,
.swal2-modal .swal2-buttonswrapper select.swal2-styled.swal2-confirm.tt-hint,
select.border-success.tt-query,
select.border-success.tt-hint {
  outline: 1px solid #19c0ff;
}
select.form-control.border-success:focus,
select.border-success.asColorPicker-input:focus,
.swal2-modal
  .swal2-buttonswrapper
  select.asColorPicker-input.swal2-styled.swal2-confirm:focus,
.dataTables_wrapper select.border-success:focus,
.dataTables_wrapper
  .swal2-modal
  .swal2-buttonswrapper
  select.swal2-styled.swal2-confirm:focus,
.swal2-modal
  .swal2-buttonswrapper
  .dataTables_wrapper
  select.swal2-styled.swal2-confirm:focus,
.swal2-modal
  .swal2-buttonswrapper
  select.form-control.swal2-styled.swal2-confirm:focus,
.swal2-modal
  .swal2-buttonswrapper
  select.swal2-styled.swal2-confirm.tt-query:focus,
.swal2-modal
  .swal2-buttonswrapper
  select.swal2-styled.swal2-confirm.tt-hint:focus,
select.border-success.tt-query:focus,
select.border-success.tt-hint:focus {
  outline: 1px solid #19c0ff;
}
select.form-control.border-info,
select.border-info.asColorPicker-input,
.dataTables_wrapper select.border-info,
select.border-info.tt-query,
select.border-info.tt-hint {
  outline: 1px solid #4d7cff;
}
select.form-control.border-info:focus,
select.border-info.asColorPicker-input:focus,
.dataTables_wrapper select.border-info:focus,
select.border-info.tt-query:focus,
select.border-info.tt-hint:focus {
  outline: 1px solid #4d7cff;
}
select.form-control.border-warning,
select.border-warning.asColorPicker-input,
.dataTables_wrapper select.border-warning,
select.border-warning.tt-query,
select.border-warning.tt-hint {
  outline: 1px solid #f5a623;
}
select.form-control.border-warning:focus,
select.border-warning.asColorPicker-input:focus,
.dataTables_wrapper select.border-warning:focus,
select.border-warning.tt-query:focus,
select.border-warning.tt-hint:focus {
  outline: 1px solid #f5a623;
}
select.form-control.border-danger,
select.border-danger.asColorPicker-input,
.dataTables_wrapper select.border-danger,
select.border-danger.tt-query,
select.border-danger.tt-hint {
  outline: 1px solid #ff469d;
}
select.form-control.border-danger:focus,
select.border-danger.asColorPicker-input:focus,
.dataTables_wrapper select.border-danger:focus,
select.border-danger.tt-query:focus,
select.border-danger.tt-hint:focus {
  outline: 1px solid #ff469d;
}
select.form-control.border-light,
select.border-light.asColorPicker-input,
.dataTables_wrapper select.border-light,
select.border-light.tt-query,
select.border-light.tt-hint {
  outline: 1px solid #f8f9fa;
}
select.form-control.border-light:focus,
select.border-light.asColorPicker-input:focus,
.dataTables_wrapper select.border-light:focus,
select.border-light.tt-query:focus,
select.border-light.tt-hint:focus {
  outline: 1px solid #f8f9fa;
}
select.form-control.border-dark,
select.border-dark.asColorPicker-input,
.dataTables_wrapper select.border-dark,
select.border-dark.tt-query,
select.border-dark.tt-hint {
  outline: 1px solid #3d4465;
}
select.form-control.border-dark:focus,
select.border-dark.asColorPicker-input:focus,
.dataTables_wrapper select.border-dark:focus,
select.border-dark.tt-query:focus,
select.border-dark.tt-hint:focus {
  outline: 1px solid #3d4465;
}
.form-group label {
  font-size: 0.75rem;
  line-height: 1;
  vertical-align: top;
  margin-bottom: 0.5rem;
  color: var(--primary-text-color);
  min-width: 120;
}
.form-group.has-danger .form-control,
.form-group.has-danger .asColorPicker-input,
.form-group.has-danger .dataTables_wrapper select,
.dataTables_wrapper .form-group.has-danger select,
.form-group.has-danger .tt-query,
.form-group.has-danger .tt-hint {
  border-color: #ff469d;
}
.form-group .file-upload-default {
  visibility: hidden;
  position: absolute;
}
.form-group .file-upload-info {
  background: transparent;
}
/* Lists */
ul,
ol,
dl {
  padding-left: 1rem;
  font-size: 0.875rem;
}
ul li,
ol li,
dl li {
  line-height: 1.8;
}
/* Modals */
.modal .modal-dialog {
  margin-top: 100px;
}

.modal-content {
  background: var(--card-bg);
}
.modal .modal-dialog .modal-content .modal-header {
  padding: 25px 26px;
}
.modal .modal-dialog .modal-content .modal-header .close span {
  font-size: 20px;
  font-weight: 400;
  color: var(--primary-text-color);
}
.modal .modal-dialog .modal-content .modal-body {
  padding: 35px 26px;
}
.modal .modal-dialog .modal-content .modal-footer {
  padding: 15px 31px;
}
@media (max-width: 320px) {
  .modal .modal-dialog .modal-content .modal-footer {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
}
/* Pagination */
.pagination .page-item .page-link {
  border: 0;
  background-color: rgba(200, 203, 215, 0.24);
  color: #6c7293;
  font-size: 14px;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  border-radius: 6px;
  font-weight: 500;
}
.pagination .page-item .page-link:focus {
  background: inherit;
}
.pagination .page-item .page-link i:before {
  font-size: inherit;
  line-height: 1;
  vertical-align: middle;
}
.pagination .page-item.active .page-link {
  background: #16a6dc;
  border-color: #16a6dc;
  color: var(--primary-color);
}
/* pagination variations */
.pagination-primary .page-item.active .page-link {
  background: #16a6dc;
  border-color: #16a6dc;
}
.pagination-primary .page-item:hover .page-link {
  background: #6d60ff;
  border-color: #16a6dc;
  color: var(--primary-color);
}
.pagination-secondary .page-item.active .page-link {
  background: #9097c4;
  border-color: #9097c4;
}
.pagination-secondary .page-item:hover .page-link {
  background: #a1a7cd;
  border-color: #9097c4;
  color: var(--primary-color);
}
/* Preview */
.preview-list .preview-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  padding: 1.25rem 0;
}
.preview-list .preview-item:last-child {
  border-bottom: 0;
}
.preview-list .preview-item .form-check {
  margin-top: 8px;
  margin-right: 1rem;
}
.preview-list .preview-item .preview-thumbnail {
  color: var(--card-bg);
  position: relative;
}
.preview-list .preview-item .preview-thumbnail img,
.preview-list .preview-item .preview-thumbnail .preview-icon {
  width: 36px;
  height: 36px;
  border-radius: 100%;
}
.preview-list .preview-item .preview-thumbnail .preview-icon {
  padding: 6px;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.preview-list .preview-item .preview-thumbnail .preview-icon i {
  font-size: 1.125rem;
  margin: 0;
}
.preview-list .preview-item .preview-thumbnail .badge {
  border: 2px solid var(--primary-color);
  border-radius: 100%;
  bottom: 5px;
  display: block;
  height: 14px;
  left: -5px;
  padding: 0;
  position: absolute;
  width: 14px;
}
.preview-list .preview-item .preview-item-content {
  line-height: 1;
  padding-left: 10px;
}
.preview-list .preview-item .preview-item-content:first-child {
  padding-left: 0;
}
.preview-list .preview-item .preview-item-content p {
  margin-bottom: 10px;
}
.preview-list .preview-item .preview-item-content p .content-category {
  font-family: "Rubik", "Roboto", sans-serif;
  padding-right: 10px;
  border-right: 1px solid #eeeff7;
}
.preview-list .preview-item .preview-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.preview-list .preview-item .preview-actions i {
  width: 29px;
  color: #e6e9ed;
  height: 29px;
  border: 2px solid #e6e9ed;
  border-radius: 100%;
  padding: 3px 6px;
  display: inline-block;
}
.preview-list .preview-item .preview-actions i:first-child {
  margin-right: 10px;
}
.preview-list.comment-preview .preview-item {
  padding: 0.87rem 0;
}
.preview-list.comment-preview .preview-item:first-child {
  padding-top: 0;
}
.preview-list.comment-preview .preview-item p {
  line-height: 27px;
}
.preview-list.bordered .preview-item {
  border-bottom: 1px solid #eeeff7;
}
.preview-list.bordered .preview-item:last-child {
  border-bottom: 0;
}
/* Tables */
.table {
  margin-bottom: 0;
}
.table thead th {
  border-bottom-width: 1px;
  font-weight: 500;
  font-size: 0.875rem;
}
.table thead th i {
  margin-left: 0.325rem;
}
.table th,
.table td {
  vertical-align: middle;
  line-height: 1;
}

.logRow {
  cursor: pointer;
}

.table tbody .logRow:hover td {
  background-color: var(--hover);
}

.table th tbody tr {
  background-color: red;
  cursor: pointer;
}

.table td {
  font-size: 0.875rem;
  padding: 1.4rem 0.6375rem;
}
.table td img {
  width: 36px;
  height: 36px;
  border-radius: 100%;
}
.table td .badge {
  margin-bottom: 0;
}

.table.table-borderless {
  border: none;
}
.table.table-borderless tr,
.table.table-borderless td,
.table.table-borderless th {
  border: none;
}
.table.table-hover tbody tr:hover {
  background: #efefef;
}
.table:not(.table-bordered) th {
  border-top: 0;
}

.nav-tabs .nav-link {
  background: #f6f8fa;
  color: var(--primary-text-color);
  border-radius: 0;
  border: 1px solid #eeeff7;
  padding: 0.75rem 1.5rem;
}
@media (max-width: 767px) {
  .nav-tabs .nav-link {
    padding: 0.75rem 0.5rem;
  }
}
.nav-tabs .nav-item:first-child .nav-link {
  border-radius: 4px 0 0 0;
}
.nav-tabs .nav-item:last-child .nav-link {
  border-radius: 0 4px 0 0;
}
.nav-tabs.nav-tabs-vertical,
.nav-tabs.nav-tabs-vertical-custom {
  border-bottom: 0;
}
.nav-tabs.nav-tabs-vertical .nav-link.active,
.nav-tabs.nav-tabs-vertical-custom .nav-link.active {
  border-bottom-color: #eeeff7;
}
.nav-tabs.nav-tabs-vertical .nav-item:first-child .nav-link,
.nav-tabs.nav-tabs-vertical-custom .nav-item:first-child .nav-link {
  border-radius: 4px 4px 0 0;
}
.nav-tabs.nav-tabs-vertical .nav-item:last-child .nav-link,
.nav-tabs.nav-tabs-vertical-custom .nav-item:last-child .nav-link {
  border-radius: 0 0 4px 4px;
}
.nav-tabs.nav-tabs-vertical-custom {
  background: #16a6dc;
  border-radius: 4px;
}
.gauge2 {
  fill: #fff;
  color: #fff;
}
.nav-tabs.nav-tabs-vertical-custom .nav-link {
  background: transparent;
  border-color: rgba(255, 255, 255, 0.2);
  color: #0f00ac;
  font-weight: 600;
  padding: 1.75rem;
}
.nav-tabs.nav-tabs-vertical-custom .nav-link.active {
  color: var(--primary-color);
  border-color: rgba(255, 255, 255, 0.2);
}
.nav-pills {
  border-bottom: 1px solid #eeeff7;
  padding-bottom: 1rem;
}
.nav-pills .nav-link {
  border: 1px solid #eeeff7;
  padding: 0.5rem 1.75rem;
}
@media (max-width: 767px) {
  .nav-pills .nav-link {
    padding: 0.5rem 0.5rem;
  }
}
.nav-pills .nav-item {
  margin-right: 1rem;
}

@media (max-width: 767px) {
  .nav-pills .nav-item {
    margin-right: 0.5rem;
  }
}
.nav-pills.nav-pills-vertical {
  border-bottom: 0;
}
.nav-pills.nav-pills-vertical .nav-item {
  margin-right: 0;
  margin-bottom: 1rem;
}
.nav-pills.nav-pills-custom {
  border-bottom: 0;
}
.nav-pills.nav-pills-custom .nav-link {
  border-radius: 0;
  padding: 0.2rem 1rem;
  background: var(--primary-color);
  border: 0;
  color: #000;
}
.nav-pills.nav-pills-custom .nav-link.active {
  background: #16a6dc;
  color: var(--primary-color);
  box-shadow: 0 13px 21px -4px #bcbbcc;
  -moz-box-shadow: 0 13px 21px -4px #bcbbcc;
  -webkit-box-shadow: 0 13px 21px -4px #bcbbcc;
}
.tab-content {
  border: 1px solid #eeeff7;
  border-top: 0;
  padding: 2rem 1rem;
  text-align: justify;
}
.tab-content.tab-content-vertical {
  border-top: 1px solid #eeeff7;
}
.tab-content.tab-content-vertical-custom {
  border: 0;
  padding-top: 0;
}
.tab-content.tab-content-custom-pill {
  border: 0;
  padding-left: 0;
}
/* Tabs */
.add-items {
  margin-bottom: 1.5rem;
  overflow: hidden;
}
.add-items input[type="text"] {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
  background: transparent;
}
.add-items .btn,
.add-items .swal2-modal .swal2-buttonswrapper .swal2-styled,
.swal2-modal .swal2-buttonswrapper .add-items .swal2-styled,
.add-items .wizard > .actions a,
.wizard > .actions .add-items a {
  margin-left: 0.5rem;
}
.list-wrapper {
  height: 100%;
  max-height: 100%;
}
.list-wrapper ul {
  padding: 0;
  text-align: left;
  list-style: none;
  margin-bottom: 0;
}
.list-wrapper ul li {
  font-size: 0.9375rem;
  padding: 0.4rem 0;
  border-bottom: 1px solid #eeeff7;
}
.list-wrapper ul li:first-child {
  border-bottom: none;
}
.list-wrapper ul li .form-check {
  max-width: 90%;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.list-wrapper input[type="checkbox"] {
  margin-right: 15px;
}
.list-wrapper .remove {
  cursor: pointer;
  font-size: 1.438rem;
  font-weight: 600;
  width: 1.25rem;
  height: 1.25rem;
  line-height: 20px;
  text-align: center;
}
.list-wrapper .completed {
  text-decoration: line-through;
  text-decoration-color: #4d7cff;
}
/* Pricing table */
.pricing-table .pricing-card .pricing-card-body {
  padding: 50px 56px 43px 56px;
}
.pricing-table .pricing-card .pricing-card-body .plan-features {
  width: 100%;
  margin-bottom: 32px;
}
.pricing-table .pricing-card .pricing-card-body .plan-features li {
  text-align: left;
  padding: 4px 0px;
  font-weight: 400;
  font-size: 0.875rem;
}
/* Avgrund Popup */
.avgrund-popin {
  position: fixed;
  background: #fff;
  padding: 37px 33px;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  top: 50%;
  left: 50%;
  z-index: 1000;
  font-size: 0.875rem;
  font-weight: 400;
  font-weight: initial;
  line-height: 1.85;
  border-radius: 10px;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}
.avgrund-popin p {
  font-size: 0.875rem;
  font-weight: 400;
  font-weight: initial;
}
.avgrund-overlay {
  background: #000;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 101;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
}
body.avgrund-ready,
.avgrund-ready .avgrund-popin,
.avgrund-ready .avgrund-overlay {
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: 0.3s all ease-out;
  -moz-transition: 0.3s all ease-out;
  -ms-transition: 0.3s all ease-out;
  -o-transition: 0.3s all ease-out;
  transition: 0.3s all ease-out;
}
body.avgrund-active {
  overflow: hidden;
}
.avgrund-active .avgrund-popin {
  visibility: visible;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
.avgrund-active .avgrund-overlay {
  visibility: visible;
  opacity: 0.5;
  filter: alpha(opacity=50);
  height: 20000px;
}
.avgrund-popin.stack {
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
}
.avgrund-active .avgrund-popin.stack {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
/* Optional close button styles */
.avgrund-close {
  display: block;
  color: #fff;
  background: #000;
  font-size: 10px;
  text-decoration: none;
  text-transform: uppercase;
  position: absolute;
  top: 10px;
  right: 10px;
  height: 17px;
  width: 17px;
  text-align: center;
  line-height: 16px;
  border-radius: 50px;
}
.avgrund-close:hover {
  color: #fff;
  text-decoration: none;
}
/* Chartist */
.ct-series-a .ct-line {
  stroke: #6046ff;
  stroke-width: 3px;
  stroke-dasharray: 10px 20px;
}
.ct-series-a .ct-point {
  stroke: #6355f9;
  stroke-width: 10px;
  stroke-linecap: round;
}
.ct-series-b .ct-line {
  stroke: #80d691;
  stroke-width: 3px;
  stroke-dasharray: 10px 20px;
}
.ct-series-b .ct-point {
  stroke: #4ac462;
  stroke-width: 10px;
  stroke-linecap: round;
}
.ct-series-c .ct-line {
  stroke: #f7cb9b;
  stroke-width: 3px;
  stroke-dasharray: 10px 20px;
}
.ct-series-c .ct-point {
  stroke: #f2a859;
  stroke-width: 10px;
  stroke-linecap: round;
}
.ct-series-a .ct-bar {
  stroke: #6046ff;
  stroke-width: 20px;
  stroke-dasharray: 0;
  stroke-linecap: square;
}
.ct-series-b .ct-bar {
  stroke: #eff0f6;
  stroke-width: 20px;
  stroke-dasharray: 0;
  stroke-linecap: square;
}
.ct-series-a .ct-bar:nth-of-type(2n + 1) {
  stroke: #19c0ff;
}
.ct-series-c .ct-bar {
  stroke: #f7cb9b;
  stroke-width: 20px;
  stroke-dasharray: 0;
  stroke-linecap: square;
}
.ct-series-d .ct-bar {
  stroke: #fcb2b2;
  stroke-width: 20px;
  stroke-dasharray: 0;
  stroke-linecap: square;
}
.ct-series-a .ct-slice-pie {
  fill: #6046ff;
  stroke-width: 4px;
}
.ct-series-b .ct-slice-pie {
  fill: #80d691;
  stroke-width: 4px;
}
.ct-series-c .ct-slice-pie {
  fill: #fcb2b2;
  stroke-width: 4px;
}
.ct-series-d .ct-slice-pie {
  fill: #f7cb9b;
  stroke-width: 4px;
}
.ct-series-a .ct-slice-donut-solid {
  fill: #6046ff;
}
.ct-series-b .ct-slice-donut-solid {
  fill: #80d691;
}
.ct-series-c .ct-slice-donut-solid {
  fill: #fcb2b2;
}
.ct-series-a .ct-slice-donut-solid {
  fill: #f7cb9b;
}
#ct-chart-dash-barChart .ct-series-a .ct-bar {
  stroke: #fb9999;
  stroke-width: 20px;
  stroke-dasharray: 0;
  stroke-linecap: square;
}
#ct-chart-dash-barChart .ct-series-b .ct-bar {
  stroke: #8b81fb;
  stroke-width: 20px;
  stroke-dasharray: 0;
  stroke-linecap: square;
}
#ct-chart-dash-barChart .ct-series-c .ct-bar {
  stroke: #6046ff;
  stroke-width: 20px;
  stroke-dasharray: 0;
  stroke-linecap: square;
}
#ct-chart-dash-barChart .ct-series-d .ct-bar {
  stroke: #b9b3fc;
  stroke-width: 20px;
  stroke-dasharray: 0;
  stroke-linecap: square;
}
/* Colcade */
* {
  box-sizing: border-box;
  transition: background-color 0.6s ease-out, color 0.6s ease-out;
}
.grid {
  border: 1px solid #aab2bd;
}
.grid:after {
  display: block;
  content: "";
  clear: both;
}
.grid-col {
  float: left;
  width: 49%;
  margin-right: 2%;
  background: rgba(86, 70, 255, 0.2);
}
.grid-col--4 {
  margin-right: 0;
}
/* hide two middle */
.grid-col--2,
.grid-col--3 {
  display: none;
}
@media (min-width: 768px) {
  .grid-col {
    width: 32%;
  }
  .grid-col--2 {
    display: block;
  }
}
@media (min-width: 1200px) {
  .grid-col {
    width: 23.5%;
  }
  .grid-col--2,
  .grid-col--3 {
    display: block;
  }
}
.grid-item {
  background: #16a6dc;
  margin-bottom: 20px;
}
.grid-item--a {
  height: 80px;
}
.grid-item--b {
  height: 140px;
}
.grid-item--c {
  height: 300px;
}
/* Context Menu */
.context-menu-icon:before {
  color: #000;
  font: normal normal normal 15px/1 "Material Design Icons";
}
.context-menu-icon.context-menu-icon-cut:before {
  content: "\F190";
}
.context-menu-icon.context-menu-icon-edit:before {
  content: "\F3EF";
}
.context-menu-icon.context-menu-icon-copy:before {
  content: "\F18F";
}
.context-menu-icon.context-menu-icon-paste:before {
  content: "\F613";
}
.context-menu-icon.context-menu-icon-delete:before {
  content: "\F6CB";
}
.context-menu-icon.context-menu-icon-quit:before {
  content: "\F156";
}
.context-menu-list {
  box-shadow: none;
  border: 1px solid #eeeff7;
}
/* Flot chart */
.flot-chart-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 300px;
  max-height: 100%;
}
.flot-chart {
  width: 100%;
  position: relative;
  max-width: none;
  height: 400px;
}
.flot-chart canvas {
  position: absolute;
  top: 0;
  bottom: 0;
}
/* Google Charts */
.google-chart-container .google-charts {
  width: 100%;
  height: 280px;
  min-height: 280px;
  min-width: 100%;
}
/* Jvectormap */
.jvectormap-container {
  background: var(--primary-color);
}
/* Listify */
.listify-list input {
  border: 1px solid #f2f7f8;
  color: #aab2bd;
  background: #fff;
}
.listify-list ul.list {
  list-style: none;
  padding-left: 0;
}
.listify-list ul.list li {
  display: block;
  border-bottom: 1px solid #eeeff7;
  padding: 15px 10px;
}
.listify-list ul.list li h5 {
  color: #16a6dc;
}
.listify-list ul.list li p {
  color: #aab2bd;
  margin: 0;
}
.mapael svg {
  max-width: 100%;
}
/* Progressbar-js */
.progress-bar-js-line {
  height: 6px;
}
.progressbar-js-circle {
  width: 100%;
  position: relative;
}

.progress-bar {
  background-color: #32cd32;
}

.progress {
  background-color: var(--background);
}
/*-------------------------------------------------------------------*/
/* === Landing screens === */
/* Auth */
.auth .login-half-bg {
  background: url("../images/auth/login-bg-1.png");
  /* background: url("../images/auth/login-bg.jpg"); */
  background-size: cover;
  display: flex;
}
.auth .register-half-bg {
  background: url("../images/auth/register-bg.jpg");
  background-size: cover;
}
.auth.lock-full-bg {
  background: url("../images/auth/lockscreen-bg.jpg");
  background-size: cover;
}
.auth .lock-profile-img {
  width: 90px;
  height: 90px;
  border-radius: 100%;
}
.auth .auth-form-light {
  background: var(--primary-color);
}
.auth .auth-form-light select {
  color: #c9c8c8;
}
.auth .auth-form-light .input-group .form-control:focus,
.auth .auth-form-light .asColorPicker-wrap .form-control:focus,
.auth .auth-form-light .input-group .asColorPicker-input:focus,
.auth .auth-form-light .asColorPicker-wrap .asColorPicker-input:focus,
.auth .auth-form-light .input-group .dataTables_wrapper select:focus,
.dataTables_wrapper .auth .auth-form-light .input-group select:focus,
.auth .auth-form-light .asColorPicker-wrap .dataTables_wrapper select:focus,
.dataTables_wrapper .auth .auth-form-light .asColorPicker-wrap select:focus,
.auth .auth-form-light .asColorPicker-wrap .tt-query:focus,
.auth .auth-form-light .input-group .tt-hint:focus,
.auth .auth-form-light .asColorPicker-wrap .tt-hint:focus,
.auth .auth-form-light .input-group .form-control:active,
.auth .auth-form-light .asColorPicker-wrap .form-control:active,
.auth .auth-form-light .input-group .asColorPicker-input:active,
.auth .auth-form-light .asColorPicker-wrap .asColorPicker-input:active,
.auth .auth-form-light .input-group .dataTables_wrapper select:active,
.dataTables_wrapper .auth .auth-form-light .input-group select:active,
.auth .auth-form-light .asColorPicker-wrap .dataTables_wrapper select:active,
.dataTables_wrapper .auth .auth-form-light .asColorPicker-wrap select:active,
.auth .auth-form-light .input-group .tt-query:active,
.auth .auth-form-light .asColorPicker-wrap .tt-query:active,
.auth .auth-form-light .input-group .tt-hint:active,
.auth .auth-form-light .asColorPicker-wrap .tt-hint:active {
  border-color: #eeeff7;
}
.auth .auth-form-transparent {
  background: transparent;
}
.auth .auth-form-transparent h4 {
  font-size: 26px;
  font-weight: 500;
  color: var(--primary-text-color);
}
.auth .auth-form-transparent h6 {
  font-size: 18px;
  line-height: 1.44;
  color: #b7b7b7;
}
.auth .auth-form-transparent .form-control,
.auth .auth-form-transparent .asColorPicker-input,
.auth .auth-form-transparent .dataTables_wrapper select,
.dataTables_wrapper .auth .auth-form-transparent select,
.auth .auth-form-transparent .tt-query,
.auth .auth-form-transparent .tt-hint,
.auth .auth-form-transparent .input-group-text {
  border: 0;
}
.auth .auth-form-transparent .form-control:focus,
.auth .auth-form-transparent .asColorPicker-input:focus,
.auth .auth-form-transparent .dataTables_wrapper select:focus,
.dataTables_wrapper .auth .auth-form-transparent select:focus,
.auth .auth-form-transparent .tt-query:focus,
.auth .auth-form-transparent .tt-hint:focus,
.auth .auth-form-transparent .form-control:active,
.auth .auth-form-transparent .asColorPicker-input:active,
.auth .auth-form-transparent .dataTables_wrapper select:active,
.dataTables_wrapper .auth .auth-form-transparent select:active,
.auth .auth-form-transparent .tt-query:active,
.auth .auth-form-transparent .tt-hint:active,
.auth .auth-form-transparent .input-group-text:focus,
.auth .auth-form-transparent .input-group-text:active {
  border-color: #9097c4;
  background: var(--background);
}
.auth .auth-form-transparent select {
  outline-color: #9097c4;
}
.auth.auth-img-bg {
  padding: 0;
}
@media (min-width: 768px) {
  .auth.auth-img-bg .auth-form-transparent {
    width: 73%;
    margin: auto;
  }
}
.auth .brand-logo {
  margin-bottom: 2rem;
}
.auth .brand-logo img {
  width: 150px;
}
.auth form .form-group {
  margin-bottom: 0;
  background: var(--primary-color);
  padding: 17px 25px;
}
.auth form .form-group:first-of-type {
  border-radius: 8px 8px 0 0;
}
.auth form .form-group + .form-group {
  border-top: 1px solid #ebebeb;
  border-radius: 0 0 8px 8px;
}
.auth form .form-group label {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--primary-text-color);
}
.auth form .form-group .input-group input {
  padding: 0;
  color: var(--primary-text-color);
}
.auth form .form-group .input-group .input-group-text {
  padding: 0;
  margin-top: -20px;
}
.auth form .form-group .input-group .input-group-text .mdi {
  font-size: 1.2rem;
}
.box-register {
  background: #d8d8d8;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}
.box-register a {
  display: block;
  padding: 30px 0;
  text-decoration: none;
  color: #1f1f1f;
  font-size: 18px;
}
.login-half-text {
  opacity: 0.95;
  text-align: center;
  vertical-align: middle;
  margin: auto;
}
.login-half-text p {
  font-size: 1.375rem;
  line-height: 2.125rem;
}
.auth form .form-group .form-control,
.auth form .form-group .asColorPicker-input,
.auth form .form-group .dataTables_wrapper select,
.dataTables_wrapper .auth form .form-group select,
.auth form .form-group .tt-query,
.auth form .form-group .tt-hint {
  background: var(--card-bg);
  border-radius: 0;
  font-size: 0.9375rem;
}
.auth form .auth-form-btn {
  width: 160px;
  height: 44px;
  border-radius: 6px;
  box-shadow: 0 15px 25px 0 rgba(68, 75, 174, 0.1);
  background-color: #16a6dc;
}
.auth form .auth-link {
  font-size: 14px;
  white-space: nowrap;
  padding-left: 20px;
  color: #b7b7b7;
}
.auth form .auth-link:hover {
  color: initial;
}
.page-body-wrapper {
  min-height: calc(100vh - 69px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
  padding-top: 69px;
  justify-content: flex-end;
}
.page-body-wrapper.full-page-wrapper {
  width: 100%;
  min-height: 100vh;
  padding-top: 0;
}
.main-panel {
  transition: width 0.25s ease, margin 0.25s ease;
  width: calc(100% - 210px);
  min-height: calc(100vh - 69px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  position: relative;
}
@media (max-width: 991px) {
  .main-panel {
    margin-left: 0;
    width: 100%;
  }
}
.content-wrapper {
  background: var(--background);
  padding: 2rem;
  width: 100%;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
/* Sidebar */
.sidebar {
  height: calc(100vh - 80px);
  background: var(--sidebar-bg);
  font-family: "Rubik", "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 500;
  padding: 0;
  width: 210px;
  z-index: 1000;
  /* z-index: 9999; */
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease;
  box-shadow: 6px 16px 31px -18px var(--card-shadow);
  -webkit-box-shadow: 6px 16px 31px -18px var(--card-shadow);
  -moz-box-shadow: 6px 16px 31px -18px var(--card-shadow);
  -ms-box-shadow: 6px 16px 31px -18px var(--card-shadow);
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  position: relative;
  position: fixed;
  left: 0;
  bottom: 0;
  justify-content: center;
  padding-top: 56px;
}
.sidebar .nav {
  overflow: hidden;
  flex-wrap: nowrap;
  flex-direction: column;
  margin-bottom: 60px;
  width: 100%;
  padding: 0 30px;
}
.sidebar-icon-only .sidebar .nav {
  padding: 0 15px;
}
.sidebar .nav .nav-item {
  -webkit-transition-duration: 0.25s;
  -moz-transition-duration: 0.25s;
  -o-transition-duration: 0.25s;
  transition-duration: 0.25s;
  transition-property: background;
  -webkit-transition-property: background;
}
.sidebar .nav .nav-item .collapse {
  z-index: 999;
}
.sidebar .nav .nav-item .nav-link {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  white-space: nowrap;
  padding: 0.875rem 1.375rem 0.875rem 0.875rem;
  color: #fff;
  -webkit-transition-duration: 0.45s;
  -moz-transition-duration: 0.45s;
  -o-transition-duration: 0.45s;
  transition-duration: 0.45s;
  transition-property: color;
  -webkit-transition-property: color;
  border-radius: 7px;
}
.sidebar .nav .nav-item .nav-link i {
  color: inherit;
}
.sidebar .nav .nav-item .nav-link i.menu-icon {
  font-size: 1.05rem;
  line-height: 1;
  margin-right: 0.625rem;
  color: #fff;
}
.sidebar .nav .nav-item .nav-link i.menu-icon:before {
  vertical-align: middle;
}
.sidebar .nav .nav-item .nav-link i.menu-arrow {
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: auto;
  margin-right: 0;
  color: #bfbcff;
}
.sidebar .nav .nav-item .nav-link i.menu-arrow:before {
  content: "\F142";
  font-family: "Rubik", "Roboto", sans-serif;
  font-style: normal;
  display: block;
  font-size: 1rem;
  line-height: 10px;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.sidebar .nav .nav-item .nav-link .menu-title {
  color: inherit;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1;
  vertical-align: middle;
  font-weight: 500;
}
.sidebar .nav .nav-item .nav-link .badge {
  margin-left: auto;
}
.sidebar .nav .nav-item .nav-link[aria-expanded="true"] i.menu-arrow:before {
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sidebar .nav .nav-item.active > .nav-link {
  background: var(--sidebar-item);
  position: relative;
}
.sidebar .nav .nav-item.active > .nav-link i,
.sidebar .nav .nav-item.active > .nav-link .menu-title,
.sidebar .nav .nav-item.active > .nav-link .menu-arrow {
  color: #fff;
}
.sidebar .nav:not(.sub-menu) {
  margin-top: 0.5rem;
}
.sidebar .nav:not(.sub-menu) > .nav-item {
  margin-top: 0.625rem;
}
.sidebar .nav:not(.sub-menu) > .nav-item > .nav-link {
  margin: 0;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.sidebar .nav:not(.sub-menu) > .nav-item:hover > .nav-link {
  background: var(--sidebar-hover);
  color: #fff;
}
.sidebar .nav.sub-menu {
  margin-bottom: 0;
  margin-top: 0;
  list-style: none;
  padding: 0.25rem 0 0 3.75rem;
}
.navbar-editor {
  position: absolute;
  bottom: 5px;
  color: #fff;
  background: transparent;
  border: 0;
  cursor: pointer;
  margin-right: 50px;
}

.navbar-editor2 {
  position: absolute;
  bottom: 45px;
  color: #fff;
  opacity: 0.5;
  background: transparent;
  border: 0;
  cursor: pointer;
  margin-right: 40px;
  margin-bottom: 8px;
}
.navbar-editor small {
  font-size: 0.75rem;
  padding-left: 5px;
}
.sidebar-light .sidebar {
  background: var(--primary-color);
}
.sidebar-light .sidebar .nav .nav-item .nav-link {
  color: #686868;
}
.sidebar-light .sidebar .nav .nav-item .nav-link i {
  color: inherit;
}
.sidebar-light .sidebar .nav .nav-item .nav-link i.menu-icon {
  color: #16a6dc;
}
.sidebar-light .sidebar .nav .nav-item .nav-link .menu-title {
  color: #16a6dc;
}
.sidebar-light .sidebar .nav .nav-item.active > .nav-link {
  background: #eff0f6;
}
.sidebar-light .sidebar .nav .nav-item.active > .nav-link .menu-title,
.sidebar-light .sidebar .nav .nav-item.active > .nav-link i {
  color: #16a6dc;
}
.sidebar-light .sidebar .nav:not(.sub-menu) > .nav-item:hover > .nav-link {
  background: #eff0f6;
  color: #56595a;
}
.management-menu {
  margin: 0 -20px;
}
.management-menu .nav .nav-item > .nav-link {
  color: #707070;
}
.management-menu .nav .nav-item.active > .nav-link i {
  color: #fff;
}
.management-menu .nav .nav-item.active > .nav-link {
  background: var(--primary-blue);
  color: #fff;
  border-radius: 3px;
}
/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - 69px);
    top: 69px;
    bottom: 0;
    overflow: auto;
    right: -210px;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    left: auto;
  }
  .sidebar-offcanvas.active {
    right: 0;
  }
  .sidebar-icon-only .sidebar-offcanvas.active {
    right: -210px;
  }
}
/* Navbar */
.navbar {
  font-weight: 400;
  transition: background 0.25s ease;
  -webkit-transition: background 0.25s ease;
  -moz-transition: background 0.25s ease;
  -ms-transition: background 0.25s ease;
  /* Navbar color variations */
}
.navbar .navbar-brand-wrapper {
  background: var(--sidebar-bg);
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease;
  width: 210px;
  height: 80px;
}
.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper {
  margin-right: 2.3rem;
  margin-left: 2.3rem;
}
.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-brand {
  color: #27367f;
  font-size: 1.5rem;
  margin: 24px 0 10px;
  padding: 0.25rem 0;
}
.navbar
  .navbar-brand-wrapper
  .navbar-brand-inner-wrapper
  .navbar-brand.brand-logo-mini {
  display: none;
}
@media (max-width: 991px) {
  .navbar
    .navbar-brand-wrapper
    .navbar-brand-inner-wrapper
    .navbar-brand.brand-logo-mini {
    display: inline-block;
    width: 1.875rem;
  }
}
.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-brand:active,
.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-brand:focus,
.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-brand:hover {
  color: #1b2658;
}
.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-brand img {
  max-width: 100%;
  margin: auto;
  vertical-align: middle;
}
.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-toggler {
  border: 0;
  color: var(--primary-text-color);
  font-size: 1.5rem;
  padding: 0;
}
.navbar-nav h4 {
  font-weight: normal;
}
@media (max-width: 991px) {
  .navbar
    .navbar-brand-wrapper
    .navbar-brand-inner-wrapper
    .navbar-toggler:not(.navbar-toggler-right) {
    display: none;
  }
}
@media (max-width: 991px) {
  .navbar
    .navbar-brand-wrapper
    .navbar-brand-inner-wrapper
    .navbar-toggler.navbar-toggler-right {
    padding-left: 15px;
    padding-right: 11px;
  }
}
.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .brand-logo-mini {
  padding-left: 0;
  text-align: center;
}
.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .brand-logo-mini img {
  width: 32px;
  max-width: 100%;
  margin: auto;
}
.navbar .navbar-menu-wrapper {
  background: var(--background);
  transition: width 0.25s ease;
  -webkit-transition: width 0.25s ease;
  -moz-transition: width 0.25s ease;
  -ms-transition: width 0.25s ease;
  color: #6c7293;
  padding-left: 1.312rem;
  padding-right: 1.312rem;
  width: calc(100% - 210px);
  height: 80px;
  border-bottom: 1px solid var(--border);
  box-shadow: inset 0 -1px 0 0 var(--card-shadow);
  -webkit-box-shadow: inset 0 -1px 0 0 var(--card-shadow);
  -moz-box-shadow: inset 0 -1px 0 0 var(--card-shadow);
  -ms-box-shadow: inset 0 -1px 0 0 var(--card-shadow);
  -o-box-shadow: inset 0 -1px 0 0 var(--card-shadow);
}
@media (max-width: 991px) {
  .navbar .navbar-menu-wrapper {
    width: calc(100% - 55px);
    height: 69px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item {
  margin-right: 1rem;
  font-size: 28px;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item h4 {
  font-size: 28px;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item:last-child {
  margin-right: 0;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
  color: var(--primary-text-color);
  font-size: 1rem;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .nav-link.dropdown-toggle:after {
  margin-right: 1rem;
  color: rgba(183, 183, 183, 0.64);
}

.nav-link {
  color: var(--primary-text-color);
}

.nav-link:hover {
  color: var(--primary-blue);
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search {
  margin-left: 2rem;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap {
  background: rgba(255, 255, 255, 0.13);
  border-radius: 4px;
  padding: 0.75rem;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .form-control,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .form-control,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .asColorPicker-input,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .asColorPicker-input,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .dataTables_wrapper
  select,
.dataTables_wrapper
  .navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  select,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .dataTables_wrapper
  select,
.dataTables_wrapper
  .navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  select,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .tt-query,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .tt-query,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .tt-hint,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .tt-hint,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .input-group-text,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .input-group-text {
  background: transparent;
  border: 0;
  color: var(--primary-color);
  padding: 0;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .form-control,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .form-control,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .asColorPicker-input,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .asColorPicker-input,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .dataTables_wrapper
  select,
.dataTables_wrapper
  .navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  select,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .dataTables_wrapper
  select,
.dataTables_wrapper
  .navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  select,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .tt-query,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .tt-query,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .tt-hint,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .tt-hint {
  margin-left: 0.5rem;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .form-control::-webkit-input-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .form-control::-webkit-input-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .asColorPicker-input::-webkit-input-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .asColorPicker-input::-webkit-input-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .dataTables_wrapper
  select::-webkit-input-placeholder,
.dataTables_wrapper
  .navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  select::-webkit-input-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .dataTables_wrapper
  select::-webkit-input-placeholder,
.dataTables_wrapper
  .navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  select::-webkit-input-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .tt-query::-webkit-input-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .tt-query::-webkit-input-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .tt-hint::-webkit-input-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .tt-hint::-webkit-input-placeholder {
  color: var(--primary-color);
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .form-control:-moz-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .form-control:-moz-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .asColorPicker-input:-moz-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .asColorPicker-input:-moz-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .dataTables_wrapper
  select:-moz-placeholder,
.dataTables_wrapper
  .navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  select:-moz-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .dataTables_wrapper
  select:-moz-placeholder,
.dataTables_wrapper
  .navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  select:-moz-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .tt-query:-moz-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .tt-query:-moz-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .tt-hint:-moz-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .tt-hint:-moz-placeholder {
  color: var(--primary-color);
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .form-control::-moz-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .form-control::-moz-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .asColorPicker-input::-moz-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .asColorPicker-input::-moz-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .dataTables_wrapper
  select::-moz-placeholder,
.dataTables_wrapper
  .navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  select::-moz-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .dataTables_wrapper
  select::-moz-placeholder,
.dataTables_wrapper
  .navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  select::-moz-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .tt-query::-moz-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .tt-query::-moz-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .tt-hint::-moz-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .tt-hint::-moz-placeholder {
  color: var(--primary-color);
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .form-control:-ms-input-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .form-control:-ms-input-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .asColorPicker-input:-ms-input-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .asColorPicker-input:-ms-input-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .dataTables_wrapper
  select:-ms-input-placeholder,
.dataTables_wrapper
  .navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  select:-ms-input-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .dataTables_wrapper
  select:-ms-input-placeholder,
.dataTables_wrapper
  .navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  select:-ms-input-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .tt-query:-ms-input-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .tt-query:-ms-input-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .input-group
  .tt-hint:-ms-input-placeholder,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search
  .asColorPicker-wrap
  .tt-hint:-ms-input-placeholder {
  color: var(--primary-color);
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings {
  margin: 0;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings .nav-link {
  position: relative;
  padding: 0;
  text-align: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2.5rem;
  background: var(--primary-color);
  transition: all 0.2s ease-out;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings i {
  font-size: 1.125rem;
  vertical-align: middle;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link {
  background: var(--primary-color);
  padding: 0;
  border-radius: 40px;
  padding: 5px;
}
.nav-profile .nav-link img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-profile
  .nav-link
  .nav-profile-name {
  margin-left: 0.5rem;
  font-size: 15px;
  color: var(--primary-text-color);
}
@media (max-width: 767px) {
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-profile
    .nav-link
    .nav-profile-name {
    display: none;
  }
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .nav-link {
  position: absolute;
  right: 0;
  padding: 0;
  text-align: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2.5rem;
  transition: all 0.2s ease-out;
  background: var(--primary-color);
}
input.form-control::placeholder {
  color: var(--secondary-text-color);
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search input {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 2.5rem;
  padding: 0;
  border: none;
  transition: all 0.4s ease-out;
}
@media (max-width: 767px) {
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search {
    display: none;
  }
}

.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-search.navbar-search-expanded
  input {
  width: 14rem;
  padding: 0.9rem;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu {
  border: none;
  -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
  position: absolute;
  font-size: 0.9rem;
  margin-top: 0;
  right: 0;
  left: auto;
  top: 55px;
  padding-bottom: 0.6rem;
  border-radius: 8px;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .navbar-dropdown
  .dropdown-item {

  margin-bottom: 0;
  cursor: pointer;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .navbar-dropdown
  .dropdown-item
  i {
  font-size: 17px;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .navbar-dropdown
  .dropdown-item
  .badge {
  margin-left: 2.5rem;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .navbar-dropdown
  .dropdown-item
  .ellipsis {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .navbar-dropdown
  .dropdown-divider {
  margin: 0;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown i {
  margin-right: 0.5rem;
  vertical-align: middle;
}
@media (max-width: 991px) {
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown {
    position: static;
  }
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
    left: 20px;
    right: 20px;
    top: 69px;
    width: calc(100% - 40px);
  }
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .count-indicator {
  position: relative;
  padding: 0;
  text-align: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2.5rem;
  background: var(--primary-color);
  transition: all 0.2s ease-out;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .count-indicator i {
  font-size: 1.35rem;
  margin-right: 0;
  vertical-align: middle;
  color: var(--primary-blue);
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .count-indicator
  .count {
  position: absolute;
  left: 43.5%;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background: var(--primary-red);
  top: 3px;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .count-indicator:after {
  display: none;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .count-indicator:hover,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .count-indicator[aria-expanded="true"] {
  background: var(--hover);
  color: var(--primary-color);
  -webkit-box-shadow: 0 13px 21px -4px var(--card-shadow);
  -moz-box-shadow: 0 13px 21px -4px var(--card-shadow);
  box-shadow: 0 13px 21px -4px var(--card-shadow);
}
@media (min-width: 992px) {
  .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
    margin-left: auto;
  }
}
.navbar.navbar-primary .navbar-menu-wrapper {
  background: #16a6dc;
}
.navbar.navbar-light .navbar-menu-wrapper {
  background: var(--primary-color);
  border-left: 1px solid #eeeff7;
}
.navbar.navbar-light .navbar-menu-wrapper .nav-item .nav-link {
  color: #3d4465;
}
.navbar.navbar-light .navbar-menu-wrapper .nav-item.nav-search .input-group,
.navbar.navbar-light
  .navbar-menu-wrapper
  .nav-item.nav-search
  .asColorPicker-wrap {
  background: rgba(61, 68, 101, 0.24);
}
.navbar.navbar-secondary .navbar-menu-wrapper {
  background: #9097c4;
}
.navbar.navbar-light .navbar-menu-wrapper {
  background: var(--primary-color);
  border-left: 1px solid #eeeff7;
}
.navbar.navbar-light .navbar-menu-wrapper .nav-item .nav-link {
  color: #3d4465;
}
.navbar.navbar-light .navbar-menu-wrapper .nav-item.nav-search .input-group,
.navbar.navbar-light
  .navbar-menu-wrapper
  .nav-item.nav-search
  .asColorPicker-wrap {
  background: rgba(61, 68, 101, 0.24);
}
.navbar.navbar-success .navbar-menu-wrapper {
  background: #19c0ff;
}
.navbar.navbar-light .navbar-menu-wrapper {
  background: var(--primary-color);
  border-left: 1px solid #eeeff7;
}
.navbar.navbar-light .navbar-menu-wrapper .nav-item .nav-link {
  color: #3d4465;
}
.navbar.navbar-light .navbar-menu-wrapper .nav-item.nav-search .input-group,
.navbar.navbar-light
  .navbar-menu-wrapper
  .nav-item.nav-search
  .asColorPicker-wrap {
  background: rgba(61, 68, 101, 0.24);
}
.navbar.navbar-info .navbar-menu-wrapper {
  background: #4d7cff;
}
.navbar.navbar-light .navbar-menu-wrapper {
  background: var(--primary-color);
  border-left: 1px solid #eeeff7;
}
.navbar.navbar-light .navbar-menu-wrapper .nav-item .nav-link {
  color: #3d4465;
}
.navbar.navbar-light .navbar-menu-wrapper .nav-item.nav-search .input-group,
.navbar.navbar-light
  .navbar-menu-wrapper
  .nav-item.nav-search
  .asColorPicker-wrap {
  background: rgba(61, 68, 101, 0.24);
}
.navbar.navbar-warning .navbar-menu-wrapper {
  background: #f5a623;
}
.navbar.navbar-light .navbar-menu-wrapper {
  background: var(--primary-color);
  border-left: 1px solid #eeeff7;
}
.navbar.navbar-light .navbar-menu-wrapper .nav-item .nav-link {
  color: #3d4465;
}
.navbar.navbar-light .navbar-menu-wrapper .nav-item.nav-search .input-group,
.navbar.navbar-light
  .navbar-menu-wrapper
  .nav-item.nav-search
  .asColorPicker-wrap {
  background: rgba(61, 68, 101, 0.24);
}
.navbar.navbar-light .navbar-menu-wrapper {
  background: var(--primary-color);
  border-left: 1px solid #eeeff7;
}
.navbar.navbar-light .navbar-menu-wrapper .nav-item .nav-link {
  color: #3d4465;
}
.navbar.navbar-light .navbar-menu-wrapper .nav-item.nav-search .input-group,
.navbar.navbar-light
  .navbar-menu-wrapper
  .nav-item.nav-search
  .asColorPicker-wrap {
  background: rgba(61, 68, 101, 0.24);
}
.navbar.navbar-light .navbar-menu-wrapper {
  background: #f8f9fa;
}
.navbar.navbar-light .navbar-menu-wrapper {
  background: var(--primary-color);
  border-left: 1px solid #eeeff7;
}
.navbar.navbar-light .navbar-menu-wrapper .nav-item .nav-link {
  color: #3d4465;
}
.navbar.navbar-light .navbar-menu-wrapper .nav-item.nav-search .input-group,
.navbar.navbar-light
  .navbar-menu-wrapper
  .nav-item.nav-search
  .asColorPicker-wrap {
  background: rgba(61, 68, 101, 0.24);
}
.navbar.navbar-light .navbar-menu-wrapper {
  background: var(--primary-color);
  border-left: 1px solid #eeeff7;
}
.navbar.navbar-light .navbar-menu-wrapper .nav-item .nav-link {
  color: #3d4465;
}
.navbar.navbar-light .navbar-menu-wrapper .nav-item.nav-search .input-group,
.navbar.navbar-light
  .navbar-menu-wrapper
  .nav-item.nav-search
  .asColorPicker-wrap {
  background: rgba(61, 68, 101, 0.24);
}
@media (max-width: 991px) {
  .navbar {
    flex-direction: row;
  }
  .navbar .navbar-brand-wrapper {
    width: 55px;
    height: 69px;
  }
  .navbar .navbar-brand-wrapper .navbar-brand.brand-logo {
    display: none;
  }
  .navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
    display: inline-block;
  }
  .navbar-collapse {
    display: flex;
    margin-top: 0.5rem;
  }
}
@media (max-width: 480px) {
  .navbar .navbar-brand-wrapper {
    width: 55px;
  }
  .navbar .navbar-brand-wrapper .brand-logo-mini {
    padding-top: 0px;
  }
}
/* Layouts */
@media (min-width: 992px) {
  .sidebar-mini .navbar .navbar-brand-wrapper {
    width: 200px;
  }
  .sidebar-mini .navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper {
    margin-right: 1.1rem;
    margin-left: 1.1rem;
  }
  .sidebar-icon-only.sidebar-mini
    .navbar
    .navbar-brand-wrapper
    .navbar-brand-inner-wrapper {
    margin-right: 1.4rem;
    margin-left: 1.4rem;
  }
  .sidebar-mini .navbar .navbar-menu-wrapper {
    width: calc(100% - 200px);
  }
  .sidebar-mini .sidebar {
    width: 200px;
  }
  .sidebar-mini .sidebar .nav .nav-item {
    padding: 0;
    margin-top: 0.625rem;
  }
  .sidebar-mini .sidebar .nav .nav-item .nav-link {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-direction: column;
    flex-direction: column;
    text-align: center;
    position: relative;
    border-bottom: none;
  }
  .sidebar-mini .sidebar .nav .nav-item .nav-link .menu-title {
    display: block;
    margin: auto;
  }
  .sidebar-mini .sidebar .nav .nav-item .nav-link .badge {
    margin-left: 5px;
    display: none;
  }
  .sidebar-mini .sidebar .nav .nav-item .nav-link i.menu-icon {
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0.625rem;
  }
  .sidebar-mini .sidebar .nav .nav-item .nav-link i.menu-arrow {
    display: inline-block;
    margin-left: 5px;
    position: absolute;
    top: 50%;
    right: 20px;
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .sidebar-mini .main-panel {
    width: calc(100% - 200px);
  }
  .sidebar-mini:not(.sidebar-icon-only) .sidebar .nav.sub-menu {
    padding: 0;
    border-top: none;
  }
}
@media (min-width: 992px) {
  .sidebar-icon-only .navbar .navbar-brand-wrapper {
    width: 80px;
  }
  .sidebar-icon-only .navbar .navbar-brand-wrapper .brand-logo {
    display: none;
  }
  .sidebar-icon-only .navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper {
    margin: 24px 0 10px;
  }
  .sidebar-icon-only
    .navbar
    .navbar-brand-wrapper
    .navbar-brand.brand-logo-mini {
    display: inline-block;
    margin: auto;
  }
  .sidebar-icon-only .navbar .navbar-menu-wrapper {
    width: calc(100% - 80px);
  }
  .sidebar-icon-only .sidebar {
    width: 80px;
  }
  .sidebar-icon-only .sidebar .nav {
    overflow: visible;
  }
  .sidebar-icon-only .sidebar .nav .nav-item {
    position: relative;
  }
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link {
    display: block;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: center;
    position: static;
  }
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title,
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link .badge,
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-sub-title {
    display: none;
  }
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
    border-radius: 0 5px 5px 0px;
  }
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-icon {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;
  }
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-arrow {
    display: none;
  }
  .sidebar-icon-only
    .sidebar
    .nav
    .nav-item
    .nav-link[aria-expanded]
    .menu-title {
    border-radius: 0 5px 0 0px;
  }
  .sidebar-icon-only .sidebar .nav .nav-item .collapse {
    display: none;
  }
  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    background: #16a6dc;
    padding: 0.5rem 1.4rem;
    left: 80px;
    position: absolute;
    text-align: left;
    top: 0;
    bottom: 0;
    width: 190px;
    z-index: 1;
    line-height: 1.8;
    box-shadow: 4px 0px 7px 0px rgba(182, 185, 189, 0.25);
  }
  .sidebar-light.sidebar-icon-only
    .sidebar
    .nav
    .nav-item.hover-open
    .nav-link
    .menu-title {
    background: var(--primary-color);
  }
  .sidebar-light.sidebar-icon-only
    .sidebar
    .nav
    .nav-item.hover-open
    .nav-link
    .menu-title {
    color: #000;
  }
  .sidebar-icon-only
    .sidebar
    .nav
    .nav-item.hover-open
    .nav-link
    .menu-title:after {
    display: none;
  }
  .sidebar-icon-only
    .sidebar
    .nav
    .nav-item.hover-open
    .nav-link:hover
    .menu-title {
    background: #16a6dc;
  }
  .sidebar-light.sidebar-icon-only
    .sidebar
    .nav
    .nav-item.hover-open
    .nav-link:hover
    .menu-title {
    background: var(--primary-color);
  }
  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
    display: block;
    padding: 0.5rem 0;
    background: #16a6dc;
    border-radius: 0 0 5px 0;
    position: absolute;
    left: 80px;
    width: 190px;
    box-shadow: 4px 0px 7px 0px rgba(182, 185, 189, 0.25);
  }
  .sidebar-light.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
  .sidebar-light.sidebar-icon-only
    .sidebar
    .nav
    .nav-item.hover-open
    .collapsing {
    background: var(--primary-color);
  }
  .sidebar-icon-only .sidebar .nav.sub-menu {
    padding: 0 0 0 1.5rem;
  }
  .sidebar-icon-only .main-panel {
    width: calc(100% - 70px);
  }
}
.sidebar-icon-only .navbar-editor small {
  display: none;
  transition: all 0.5s ease-in-out 0.5s;
}
@media (min-width: 992px) {
  .sidebar-hidden .sidebar {
    transition: width 0.25s ease;
    -webkit-transition: width 0.25s ease;
    -moz-transition: width 0.25s ease;
    -ms-transition: width 0.25s ease;
    width: 0;
  }
  .sidebar-hidden .main-panel {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .sidebar-absolute .page-body-wrapper {
    position: relative;
  }
  .sidebar-absolute .page-body-wrapper .sidebar {
    transition: none;
  }
  .sidebar-absolute:not(.sidebar-hidden) .sidebar {
    position: absolute;
    height: 100%;
    -webkit-box-shadow: 0 0 3px 1px #a7a3a3;
    /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: 0 0 3px 1px #a7a3a3;
    /* Firefox 3.5 - 3.6 */
    box-shadow: 0 0 3px 1px #a7a3a3;
    /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  }
  .sidebar-absolute .main-panel {
    width: 100%;
    transition: none;
  }
}
@media (min-width: 992px) {
  .sidebar-fixed .sidebar {
    position: fixed;
    max-height: auto;
  }
  .sidebar-fixed .sidebar .nav {
    max-height: calc(100vh - 69px);
    overflow: auto;
    position: relative;
  }
  .sidebar-fixed .sidebar .nav.sub-menu {
    max-height: none;
  }
  .sidebar-fixed .main-panel {
    margin-left: 210px;
  }
  .sidebar-fixed.sidebar-icon-only .main-panel {
    margin-left: 70px;
  }
}
@media (min-width: 992px) {
  .boxed-layout .container-scroller {
    background: #c6c8ca;
    padding: 0 calc((100% - 1200px) / 2);
  }
  .boxed-layout .navbar.fixed-top {
    margin: auto;
    max-width: 1200px;
  }
}
/* Settings Panel */
.settings-panel {
  border-left: 1px solid #eeeff7;
  display: block;
  position: fixed;
  top: 69px;
  right: -300px;
  bottom: 0;
  width: 300px;
  height: 100vh;
  min-height: 100%;
  background: var(--primary-color);
  -webkit-transition-duration: 0.25s;
  -moz-transition-duration: 0.25s;
  -o-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-property: right, box-shadow;
  -moz-transition-property: right, box-shadow;
  -o-transition-property: right, box-shadow;
  transition-property: right, box-shadow;
  z-index: 9999;
}
.settings-panel .nav-tabs {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  width: auto;
  margin: 0;
  padding: 0;
  background: var(--primary-color);
}
.settings-panel .nav-tabs .nav-item {
  border: none;
}
.settings-panel .nav-tabs .nav-item .nav-link {
  background: transparent;
  text-align: center;
  border: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  color: #000;
  -webkit-transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
  transition-property: color;
  -webkit-transition-property: color;
  -webkit-justify-content: center;
  justify-content: center;
}
.settings-panel .nav-tabs .nav-item .nav-link.active {
  background: transparent;
  color: #16a6dc;
}
.settings-panel .tab-content {
  border: none;
  padding: 20px 0 0px 0;
}
.settings-panel .tab-content .tab-pane.scroll-wrapper {
  position: relative;
  max-height: 100vh;
  height: 100%;
  padding-bottom: 150px;
}
.settings-panel .settings-heading {
  padding: 16px 0 13px 35px;
  font-size: 0.875rem;
  font-family: "Rubik", "Roboto", sans-serif;
  font-weight: 500;
  line-height: 1;
  color: rgba(0, 0, 0, 0.9);
  opacity: 0.9;
  margin-bottom: 0;
  border-top: 1px solid #eeeff7;
  border-bottom: 1px solid #eeeff7;
}
.settings-panel .sidebar-bg-options {
  padding: 13px 35px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1;
  color: #595959;
  background: var(--primary-color);
  -webkit-transition-duration: 0.25s;
  -moz-transition-duration: 0.25s;
  -o-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -webkit-transition-property: background;
  -moz-transition-property: background;
  -o-transition-property: background;
  transition-property: background;
}
.settings-panel .sidebar-bg-options.selected {
  background: #e6e9ed;
}
.settings-panel .color-tiles {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0px 35px 10px 35px;
  padding-top: 15px;
}
.settings-panel .color-tiles .tiles {
  margin: 10px 18px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.settings-panel .color-tiles .tiles:before {
  content: "";
  width: 0px;
  height: 0px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 100%;
  border: 0;
  -webkit-transition-duration: 0.25s;
  -moz-transition-duration: 0.25s;
  -o-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
}
.settings-panel .color-tiles .tiles.selected:before {
  width: 10px;
  height: 10px;
  opacity: 1;
  border-width: 1px;
}
.settings-panel .color-tiles .tiles.light {
  background: #fff;
  border: 1px solid #e2e4e7;
}
.settings-panel .color-tiles .tiles.default {
  background: #eff0f6;
}
.settings-panel .chat-list {
  padding-left: 0;
}
.settings-panel .chat-list .list {
  padding: 0.4rem 0.8rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  border-bottom: 1px solid #eeeff7;
}
.settings-panel .chat-list .list:last-child {
  border-bottom: none;
}
.settings-panel .chat-list .list .profile {
  position: relative;
  margin-right: 1rem;
}
.settings-panel .chat-list .list .profile img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
}
.settings-panel .chat-list .list .profile span {
  height: 0.75rem;
  width: 0.75rem;
  position: absolute;
  bottom: 0.34rem;
  right: 0;
  border: 0.13rem solid var(--primary-color);
  border-radius: 100%;
}
.settings-panel .chat-list .list .profile span.online {
  background: #19c0ff;
}
.settings-panel .chat-list .list .profile span.offline {
  background: #f5a623;
}
.settings-panel .chat-list .list .info {
  margin-right: auto;
}
.settings-panel .chat-list .list .info p {
  display: block;
  margin-bottom: 0;
}
.settings-panel .chat-list .list .info p:last-child {
  opacity: 0.5;
  font-size: 0.8rem;
}
.settings-panel .chat-list .list.active {
  background: #f8f9fa;
}
.settings-panel.open {
  right: 0;
}
.settings-panel .settings-close {
  position: absolute;
  top: 16px;
  right: 10px;
  color: #16a6dc;
  background: transparent;
  border-radius: 4px;
  padding: 0 3px;
  cursor: pointer;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  z-index: 999;
}
.settings-panel .settings-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.settings-panel .events p {
  font-family: "Rubik", "Roboto", sans-serif;
  font-weight: 400;
}
#theme-settings .settings-close {
  top: 12px;
  background: transparent;
}
#settings-trigger {
  position: fixed;
  bottom: 40px;
  right: 30px;
  height: 45px;
  width: 45px;
  background: #16a6dc;
  border: 1px solid var(--primary-color);
  z-index: 99;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  border-radius: 100%;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -ms-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}
#settings-trigger i {
  color: var(--primary-color);
  font-size: 1rem;
  line-height: 1rem;
}
@media (max-width: 991px) {
  #settings-trigger {
    display: none;
  }
}
/*# sourceMappingURL=../maps/vertical-layout-light/style.css.map */
.tbl th {
  border-bottom: 1px solid #ddd;
  padding: 20px;
  font-weight: normal;
  font-size: 13px;
  text-align: left;
  font-weight: 500;
  white-space: nowrap;
}

.tbl table {
  background: var(--background);
}
.tbl tr td:last-child,
.tbl th:last-child {
  border-left: 1px solid #ddd;
  text-align: center;
  width: 5%;
}
.tbl tr td:last-child .mdi:before {
  color: #8816dc;
}
.tbl td {
  text-align: left;
  font-size: 0.75rem;
  padding: 15px;
  color: var(--primary-text-color);
}
.tbl tr td:first-child {
  white-space: nowrap;
}
.tbl td .nav-link {
  color: #1f1f1f;
}
.tbl td.nav-email .nav-link {
  color: #8816dc;
}
.tbl tr:not(:nth-child(1)):hover {
  opacity: 0.6;
}
.tbl tr:nth-child(2) td {
  white-space: nowrap;
}
.tbl tr:nth-child(2n + 2) {
  background: #eeeff7;
}
.box-select select {
  padding: 10px;
  border: 0;
  border-radius: 6px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06);
  margin-left: 10px;
  font-size: 12px;
}
.tbl-base {
  width: 100%;
  background: var(--card-bg);
}

.jsgrid .jsgrid-table,
.table {
  background: var(--card-bg);
  color: var(--primary-text-color);
}
.tbl-base tr {
  background: var(--card-bg);
  border-radius: 5px;
  display: table-row;
}

.tbl-base tr:hover {
  background: var(--hover);
}
.tbl-base .tbl-base-separate {
  height: 10px;
}
.tbl-base tr:first-child {
  background: transparent;
}
.tbl-base tr:first-child th {
  color: var(--primary-text-color);
  font-weight: normal;
  font-size: 0.875rem;
}
.tbl-base th,
.tbl-base td {
  padding: 15px;
}
.tbl-base tr td:first-child {
  border-radius: 6px 0 0 6px;
}
.tbl-base tr td:last-child {
  border-radius: 0 6px 6px 0;
  text-align: center;
  width: 5%;
}
.card-border {
  border-radius: 8px;
  background: var(--card-bg);
  box-shadow: 0px 2px 1px var(--card-shadow);
  margin-bottom: 30px;
  overflow-x: auto;
}
.card-border .nav-profile {
  white-space: nowrap;
}
.card-border .nav-profile .nav-link {
  color: #1f1f1f;
}
.card-border .nav-email {
  color: #8816dc;
}
.form-report {
  position: relative;
}
.form-report .btn {
  background: transparent;
  width: 47px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  height: 100%;
  border: 0;
  color: #6c7293;
  min-width: unset;
}
.mdi-editor {
  color: #d6d6d6;
}
.btn-add {
  padding: 7px 15px;
  border-color: var(--btn-bg);
  border-width: 1px;
  border-radius: 6px;
  background: transparent;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: var(--primary-text-color);
  font-size: 0.875rem;
}
.btn-add:hover {
  background: var(--btn-hover-bg);
  color: var(--btn-hover-text-color);
}
.activity-select {
  position: relative;
}
.activity-select .activity-select-name {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 10px;
  font-size: 12px;
  display: block;
  color: #1f1f1f;
  text-decoration: none;
}
.activity-select-name::after {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -1px;
}
.activity-select-name[aria-expanded="true"]::after {
  transform: rotate(180deg);
}
.activity-dropdown {
  position: absolute;
  width: 100%;
  padding: 0;
}
.activity-dropdown .dropdown-item {
  padding: 10px;
  font-size: 12px;
  color: var(--primary-text-color);
}
.activity-dropdown .dropdown-item + .dropdown-item {
  border-top: 1px solid #eeeff7;
}
.txt-management {
  padding: 20px;
  border-bottom: 1px;
  font-weight: 500;
  color: var(--primary-text-color);
}
.txt-management.border-bottom {
  color: var(--primary-text-color);
}
.txt-management option {
  padding: 20px;
}
.card-management .tbl tr td:last-child,
.card-management .tbl th:last-child {
  border-left: 0;
}
.txt-note {
  font-size: 12px;
  color: #6a6a6a;
  margin-top: 10px;
  display: block;
}

.box {
  background-color: var(--card-bg);
  color: var(--primary-text-color);
  border-radius: 10px;
  overflow: hidden;
  /* height: 450px; */
  /* max-height: 35vh; */
  margin: 20px;
  -webkit-box-shadow: 0px 12px 18px -6px var(--card-shadow);
  box-shadow: 0px 12px 18px -6px var(--card-shadow);
}

.grid-box {
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.grid-box > div {
  flex-basis: calc(20% -40px);
}

.card-footer {
  border-top: 0px solid #e7eaed;
  background-color: var(--card-bg);
}

.nav-link-button {
  padding: 0px;
}
.box-scroll {
  height: 300px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  position: relative;
  position: relative;
  overflow: hidden;
}
.box-scroll-inner {
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  padding-bottom: 40px;
}
.box-scroll-more {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 41px;
  opacity: 0.75;
  border-radius: 4px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1),
    var(--primary-color)
  );
  z-index: 1;
}
.box-scroll-inner::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #ebebeb;
}
.box-scroll-inner::-webkit-scrollbar {
  width: 3px;
  background-color: #ebebeb;
}
.box-scroll-inner::-webkit-scrollbar-thumb {
  background-color: #f5f5f5;
}



.scroll-inner::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

.scroll-inner::-webkit-scrollbar-track {
  background: var(--card-bg);        /* color of the tracking area */
}

.scroll-inner::-webkit-scrollbar-thumb {
  background-color: #0CAEE3;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid var(--card-bg);  /* creates padding around scroll thumb */
}

.table-responsive::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

.table-responsive::-webkit-scrollbar-track {
  background: var(--card-bg);        /* color of the tracking area */
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #0CAEE3;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid var(--card-bg);  /* creates padding around scroll thumb */
}
.form-group {
  position: relative;
  color: var(--primary-text-color);
}
.form-group input[type="password"] + .mdi {
  position: absolute;
  right: 10px;
  bottom: 12px;
}

.sidebarStyle {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  background-color: #404040;
  color: var(--primary-color);
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;
}

.mapContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.dashboard-editor {
  display: inline-block;
  position: fixed;
  left: calc(1.75rem + 57px);
  bottom: -5rem;
  background: #fff;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 5px 7px 26px -5px #e1e1f0;
  -webkit-box-shadow: 5px 7px 26px -5px #e1e1f0;
  -moz-box-shadow: 5px 7px 26px -5px #e1e1f0;
  -ms-box-shadow: 5px 7px 26px -5px #e1e1f0;
  z-index: 3;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.sidebar-icon-only .dashboard-editor {
  bottom: 1.312rem;
}
.dashboard-editor .btn + .btn {
  margin-left: 10px;
}
.chartjs-legend .goals-chart .item {
  position: relative;
  color: var(--primary-text-color);
}

.card-editor.card .card-body {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.sidebar-icon-only .card-editor.card {
  background: transparent;
  border: 1px dashed #cfd0dd;
}
.sidebar-icon-only .card-editor .card-body {
  top: -15px;
  right: -20px;
  position: relative;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 1px 0 rgba(49, 57, 67, 0.07);
}
.mdi-applica {
  position: relative;
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 4px;
}
.mdi-applica span {
  display: inline-block;
  font-size: 24px;
  border-radius: 50%;
  position: absolute;
  background: #fff;
  width: 7px;
  height: 7px;
  display: inline-block;
}
.mdi-applica span:nth-child(1) {
  left: 2px;
  top: 3px;
}
.mdi-applica span:nth-child(2) {
  top: 3px;
  right: 5px;
}
.mdi-applica span:nth-child(3) {
  left: 2px;
  bottom: 3px;
}
.mdi-applica span:nth-child(4) {
  right: 5px;
  bottom: 3px;
}
.show-result {
  font-family: "Rubik", "Roboto", sans-serif;
  font-size: 14px;
  color: #6c7293;
}
.login-half-text .nav-profile-name {
  color: #fff;
  font-size: 15px;
}
.text-primary {
  color: var(--primary-text-color) !important;
}
.txt-note {
  font-size: 0.6875rem;
  color: #b7b7b7;
}

button .btn #aStatus {
  max-width: 10px !important;
}

button.close {
  color: red;
  margin: 0px 1px;
  opacity: 1;
  text-align: center;
  text-shadow: none;
  -webkit-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out;
  vertical-align: top;
  width: 46px;
}

.valign-center-btn {
  display: inline-flex;
  vertical-align: bottom;
  align-items: center;
}

.donut-style {
  width: 280px;
  height: 280px;
  /* border-radius: 50%;
  background-color: rgba(147, 238, 250, 0.5); */

  /* animation: glow 1s infinite alternate; */
  -webkit-transition: -webkit-transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out;
}
/* @keyframes glow {  //removing glow animation
  from {
    box-shadow: 0 0 5px -5px #93eefa;
  }
  to {
    box-shadow: 0 0 5px 5px #93eefa;
  }
} */
.donut-style:hover {
  -webkit-transform: scale(1.1);

  transform: scale(1.1);
}

.riskScore {
  animation: riskGlow 1s infinite alternate;
}

@keyframes riskGlow {
  from {
    box-shadow: 0 0 5px -5px var(--color);
  }
  to {
    box-shadow: 0 0 5px 5px var(--color);
  }
}

.mapwidget {
  padding-top: 60px;
  width: "100%";
}

.mapSelect {
  color: black;
}
.zoom {
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
}

.zoom:hover {
  transform: scale(1.25);
}

.widgetzoom {
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
}
.widgetzoom:hover {
  transform: scale(1.1);
}

.rightArrow {
  top: 150px;
  text-align: center;
  height: fit-content;
}

.leftArrow {
  top: 150px;
  text-align: center;
}

.keywordButton {
  width: 37px;
  height: 37px;
  margin-left: 2px;
  align-items: center;
}

.mdi-size {
  font-size: 36px;
  color: #16a6dc;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider:focus {
  background: #d3d3d3;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #16a6dc;
  cursor: pointer;
  margin: -2px;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #16a6dc;
  cursor: pointer;
}

.slidecontainer {
  color: var(--primary-text-color);
  background: var(--card-bg);
  border-radius: 15px;
  padding: 5px;
}

.kn-multi_select__wrapper___30BEc {
  background-color: var(--background);
  color: var(--primary-text-color);
}

.kn-selection_status__status___1qxE9 {
  color: var(--primary-text-color);
}

.kn-item__selected___3tRL6 {
  background-color: var(--btn-hover-bg);
}

.kn-item__item___kQ2Ll:hover {
  background-color: var(--btn-hover-bg);
}

.kn-selected_item__selected_item___1uiiv:hover,
.kn-selected_item__with_grouping___1Ibnd:hover {
  background-color: var(--btn-hover-bg);
}
@media (max-width: 767px) {
  .accordion.accordion-solid-header .card .card-body {
    padding: 10px;
  }
  .dashboard-editor {
    bottom: -9rem;
    left: 0;
    right: 0;
    margin: auto;
  }
  .dashboard-editor .btn {
    min-width: calc(100% / 2 - 20px);
  }
  .dashboard-editor .btn + .btn {
    margin-left: 20px;
  }
  .sidebar-icon-only .dashboard-editor {
    bottom: 0;
  }
  .content-wrapper {
    padding: 1rem;
  }
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item h4 {
    font-size: 20px;
  }
}

.mgmtUser {
  transform: scale(1.5);
}
.mgmtUser:hover {
  cursor: pointer;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="range"]::-webkit-slider-runnable-track {
    height: 15px;
    -webkit-appearance: none;
    color: #16a6dc;
    margin-top: -1px;
  }
}

input[type="range"]::-moz-range-progress {
  height: 10px;
  background-color: #16a6dc;
}

/* IE*/
input[type="range"]::-ms-fill-lower {
  height: 10px;
  background-color: #16a6dc;
}
