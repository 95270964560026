.feedback {
  z-index: 99;
}
.btn-rounded {
  border-radius: 1.5em;
}

.btn-float {
  position: absolute;
  right: 1em;
  bottom: 1em;
  &.btn-feedback {
    z-index: 99;
    position: fixed;
  }
}

.feedback-form {
  width: 450px;

  .btn {
    &.btn-arrow {
      min-width: 100px !important;
      padding: 10px 15px;
    }
    &.focus {
      box-shadow: none;
    }
    &.active {
      &.btn-arrow:after,
      &.btn-arrow:before {
        position: absolute;
        line-height: 0;
        content: "";
      }

      &.btn-arrow:before {
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        left: 50%;
        margin-left: -6px;
        border-top: 6px solid #545b62;
        bottom: -6px;
      }
      &.btn-arrow:after {
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        left: 50%;
        margin-left: -8px;

        border-top: 8px solid #545b62;
        bottom: -8px;
      }
    }
  }
}
