.tbl-base tbody tr.bg-white {
  background: var(--card-bg);
}

.react-grid-layout.layout .react-grid-item .card {
  height: 100%;
  width: 100%;
}
.react-grid-layout.layout {
  height: fit-content;
  width: 100%;
}

.notifications-list {
  max-height: 400px;
  overflow-y: scroll;
}
.card .card-body .card-header {
  cursor: pointer;
}
.navbar-nav.navbar-nav-right > li.dropdown > a,
.navbar-nav.navbar-nav-right > li.dropdown > button {
  border: none;
  cursor: pointer;
}
.preview-list .preview-item .preview-item-content p.preview-subject {
  margin-bottom: 5px;
}

.font-size-small {
  font-size: 0.7rem;
}

nav .sidebar {
  z-index: 1000;
}
button.btn,
a.btn {
  border-radius: 6px;
}
table tr a.nav-link {
  padding: 0px;
}
.modal-dialog {
  &.modal-50 {
    max-width: 50%;
  }
  &.modal-75 {
    max-width: 75%;
  }
  &.modal-80 {
    max-width: 80%;
  }
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown:hover i {
  color: #fff;
}
.it-access-switcher {
  margin-top: -30px;
  margin-bottom: 10px;
  > div {
    margin-right: 5px;
    display: inline-block;
    text-transform: uppercase;
    line-height: 28px;
  }
}

.d-flex justify-content-center status-activation-switcher {
  align-content: right;
}

.box-select-name {
  margin-right: 5px;
}
.card-body .d-flex .nav-link {
  padding-right: 0px;
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  text-align: center;
  svg {
    margin: auto;
  }
}

.card .empty-widget {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  div,
  a {
    color: #313943;
    font-family: Rubik;
    font-size: 14px;
  }
  img {
    max-width: 100%;
    display: block;
  }
  .barline {
    background-color: #767d9b;
  }
  &.unknown-devices {
    img {
      width: 155px;
    }
  }
  &.top-urls {
    img {
      height: 265px;
    }
  }
  &.top-line {
    img {
      height: 231px;
    }
  }
}

.card .empty-wid {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  div,
  a {
    color: #313943;
    font-family: Rubik;
    font-size: 14px;
  }
  img {
    width: 50%;
    display: block;
    height: 231px;
  }

  &.top-line {
    img {
      height: 231px;
    }
  }
}
.card .card-body .no-data-card {
  .has-line {
    margin: auto 10px auto 0px;
    .long-line {
      background: #eff0f6;
      width: 170px;
      height: 8px;
      // margin-top: 10px;
    }
  }
  .icon-rounded-primary {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    opacity: 0.64;
    border: solid 3.7px #eff0f6;
    background-color: #eff0f6;
    margin-top: -25px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    .dash-line {
      width: 24px;
      height: 6px;
      border-radius: 4px;
      background-color: #767d9b;
    }
  }
}

.sidebarStyle {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;
}

.zoomStyle {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  margin: 12px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px 15px;
  font-weight: bold;
}

.mapContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.badge-warning {
  background-color: #f5a623;
}
.badge-danger {
  background-color: #dc1616;
}
